import React, { useEffect } from 'react';
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LanguageIcon from "@mui/icons-material/Language";
import HelpIcon from "@mui/icons-material/Help";
import FeedbackIcon from "@mui/icons-material/Feedback";
import MailIcon from "@mui/icons-material/Mail";
import CachedIcon from '@mui/icons-material/Cached';
import Link from "@mui/material/Link";
import axios from "../utils/axiosConfig";
import Cookies from 'js-cookie';
import GenerateListIcon from "@mui/icons-material/Assessment";
import GenerateTestIcon from "@mui/icons-material/Assignment";
import PersonSearchIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux'
import { getOrgId } from '../reducer/dashboardSlice';
import { CssBaseline } from '@mui/material';
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: 36
    },
    hide: {
      display: "none"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap"
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1
      }
    },
    drawer2Open: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawer2Close: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1
      }
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    toolbar1: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding:'3px 5px 0px 5px',
    },
    active: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      "& icon": {
        color: theme.palette.primary.main
      }
    },
    inactive: {
      "& icon": {
        color: ""
      }
    },
    signOutButton: {
      marginLeft: theme.spacing(1)
    }
  }));



export default (props) => {
    const classes = useStyles();
    const theme = useTheme();
    //const [open, setOpen] = React.useState(false);
    const {open, dimensions, handleDrawerOpen, leftPanelMenu, handleDrawerClose, testType, setTypeButton, dispatch, setSelectedType,handleIconCLick,typeButton} = props;
    
    // let token = Cookies.get('token');

    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [poper, setPoper] = React.useState("");
    const [cssNav, setCssNav] = React.useState(["", "", "", "", "", "", "", ""]);
    

    //const preventDefault = event => event.preventDefault();

    

    let leftNav = [
        "My Test",
        "Feedback",
        "Survey",
        "Completed",
        "Expired",
        "Site Requirements",
        "Support",
        "User Help",
        "Ongoing Tests",
        "Pending Results",
        "Regenerate Results"
        ];

    const handlePopoverOpen = (event, text) => {
        setAnchorEl(event.currentTarget);
        setPoper(text);
      };
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPop = Boolean(anchorEl);

    const renderSwitch = param => {
        switch (param) {
          case "Tests":
            return <AssignmentIcon />;
          case "Feedback":
            return <FeedbackIcon />;
          case "Survey":
            return <AssessmentIcon />;
          case "Completed":
            return <AssignmentTurnedInIcon />;
          case "Expired":
            return <AssignmentLateIcon />;
          case "WorkFlow":
            return <AssignmentTurnedInIcon />;
          case "Site Requirements":
            return <LanguageIcon />;
          case "Support":
            return <MailIcon />;
          case "User Help":
            return <HelpIcon />;
          case "Practice":
            return <CachedIcon />;
          case "Ongoing Tests":
            return <GenerateListIcon/>;
          case "Pending Results":
            return <GenerateTestIcon/>;
          case "Regenerate Results":
            return <PersonSearchIcon/>;
          default:
            return "";
        }
      };

      const selectMenuOption = (data) =>{
        switch (data) {
          case "Tests":
            return "Tests";
          case "Completed":
            return "Tests";
          case "Expired":
            return "Tests";
          case "Feedback":
            return "Feedback";
          case "FeedbackCompleted":
            return "Feedback";  
          case "FeedbackExpired":
            return "Feedback";
          default:
            return "";
          }
      }

      useEffect(()=>{
        if(dimensions.width <= 767){
          handleDrawerClose()
        }else{
          handleDrawerOpen()
        }
      },[dimensions])

      const renderList = (text, index) => {
        return (
          <div key={index}>
            {text === "Site Requirements" ? <Divider /> : ""}
            <ListItem button style={{background: typeButton && typeButton.toString().includes(text) || selectMenuOption(typeButton) === text ? '#6f298121' : ''}} 
              onClick={e => handleIconCLick(index, text)}>
              <ListItemIcon
                onMouseEnter={e => handlePopoverOpen(e, text)}
                onMouseLeave={handlePopoverClose}
                className={cssNav[index],"gutter-icon"}
              >
                {/* {renderSwitch(text)} */}
                {(text==='User Help' ?
                  <Typography style={{paddingBottom:'0px'}}>
                    <Link
                      href="../../docs/ConductOnlineTest.html"
                      target="_blank"
                      //onClick={preventDefault}
                      rel="noopener"
                      color="inherit"
                      
                    >
                      {renderSwitch(text)}
                    </Link>
                  </Typography> 
                  
                :
                renderSwitch(text)
                )}
              </ListItemIcon>
              {/* <ListItemText

                primary={text}
                onClick={e => handleIconCLick(e, index, text)}
                className={cssNav[index]}
              /> */}
               <h5
              className={cssNav[index]}
            >{text === 'WorkFlow' ? 'Work Flow' : text}</h5>
            </ListItem>
            <Popover key={`pop${index}`}
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper
              }}
              
              open={openPop}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              // PaperProps={{
              //   style: { height: '3%' ,textAlign:"center"},
              // }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <h6 style={{marginTop:"3px"}}>{poper}</h6>
            </Popover>
          </div>
        );
      };

      const orgId = useSelector(getOrgId)

      React.useEffect(() => {
       if (orgId != undefined && orgId != "") {
        try {
          axios.get('/dashboard/getProfile',{  
              headers: {
                'Access-Control-Allow-Origin': '*',
                orgId: orgId,
              }
            }).then(profData =>{
               let userData = profData.data.profileData;
              setFirstName(userData.firstname);
              setLastName(userData.lastname);
              Cookies.set('userName', userData.firstname + " " +userData.lastname )
              // setContactNo(userData.contact_number);
              // setUserInfoId(userData.id);
              // setEmail(userData.email_address);
          }).catch(error => {
             
          })
      } catch (error) {
       
      }
    }
         // requestUserMedia();
        },[useSelector(getOrgId)])
  
    return (
        <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
          <h5><b> {firstName +' '+lastName}</b></h5>
        </div>
        <Divider />
        <List key="list">{testType.map((text, index) => renderList(text, index))}</List>
      </Drawer>
    );
}


//Drawer