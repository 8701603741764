import axios from "axios";
import Cookies from "js-cookie";
import { serverConstants, localhostConstants } from "./serverConstants";
 
const instance = axios.create({

  baseURL:
    serverConstants.find(item => window.location.href.includes(item.urlUniqueString))
      ? serverConstants.find(item => window.location.href.includes(item.urlUniqueString)).url
      : localhostConstants.url,
      
});
console.log(instance.baseURL);
instance.interceptors.request.use(
  (request) => {
    
    request.headers['Authorization'] =`Bearer ${Cookies.get("token")}`;
    //request.headers.common["Authorization"] = `Bearer ${Cookies.get("token")}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {


    // if (error.response.status === 401) {
    //   Cookies.set("sessionTime", "2");
    //   Cookies.set("login", "", { path: "/" });
    //   Cookies.set("token", "", { path: "/" });
    //   window.location.href = "/v2/user/login";
    // }

    console.log('error 101', error.config)

    if (error.config && error.config.url && error.config.url.includes("/answeredQuestions")) {
      // If it's the '/answeredQuestions' route, do not redirect
      console.log("Error on /answeredQuestions, not redirecting.");
    } else {
      // Existing error handling for other routes
      console.log("Error 101:", error);

      if (error.response && error.response.status === 401) {
        Cookies.set("sessionTime", "2");
        Cookies.set("login", "", { path: "/" });
        Cookies.set("token", "", { path: "/" });
        window.location.href = "/v2/user/login";
      }
    }


    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
