import React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { SUPPORT_MAIL } from '../utils/constants';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    btnPadding :{
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 80,
      padding: theme.spacing(3),
      // overflow:"scroll",
    }
  }));

  export default (props) => {
    const classes = useStyles();

    const {typeButton} = props;

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <b><h3 style={{color:"#7030a0",fontSize:"20px"}}>{typeButton === 'Support' ? 'Assesshub Support' : 'User Help'}</h3></b>

         

  
  <Grid item xs={12}>
            
         <div className="col-md-12"  style={{background:'#ffffff',padding:'10px',borderRadius:'10px',textAlign:'justify'}}>
{typeButton === 'Support' ? 
<h5>
<b>Email Support (24*7) :</b> Send an email with your login credentials and screenshot of the error to <b>{SUPPORT_MAIL}</b>.
</h5>
: 
<h5>
Kindly check the new tab for help
</h5>
}

</div>

          
         </Grid>
    </main>
    )

}