import React, { useEffect } from "react";
import { useTheme } from '@mui/styles';
import { makeStyles } from "@mui/styles";
import axios from "../utils/axiosConfig";
import resultAxios from "../utils/resultAxiosConfig";
import axiosInstance from "axios";
import Cookies from "js-cookie";
import cache from "memory-cache";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import QuestionNoList from "../components/QuestionNoList";

import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

import TestAppBar from "../components/Test/AppBar";
import TimerBar from "../components/Test/TimerAppBar";
import InstanceBar from "../components/Test/InstanceBar";
import MultipleanswerAllCorrect from "../components/Test/QuestionAnswerType/MultipleAnswerAllCorrect";
import MultipleSelectAnswer from "../components/Test/QuestionAnswerType/MultipleSelectAnswer";
import AnswerUserInfo from "../components/Test/AnswerUserInfo";
import Passage from "../components/Test/QuestionAnswerType/PassageMultipleChoiseQuestion";

import Proceed from "../components/Test/Proceed";
import ProgressBar from "../components/Test/ProgressBar";

import WindowFocusHandler from "../components/WindowFocusHandler";
import useUnload from "../components/UseUnload";
import TimePerQuestion from "../components/Test/TimerPerQuestion";
import TimeOutModal from "../components/Test/TimeOutModal";
import TimeOutModalOneMinute from "../components/Test/TimeOutOneMinuteModal";

import FinishModal from "../components/Test/FinishModal";
import FinishModalDetail from "../components/Test/FinishModalDetail";
import LastQuestionModal from "../components/Test/LastQuestionModal";
import ProctoringError from "../components/Test/proctoringErrorModel";
import ShowDetails from "../components/Test/ShowDetails";
import InstructionPage from "../components/Test/Instruction";
import ThankyouPage from "../components/Test/Thankyou";
import FillInTheBlank from "../components/Test/QuestionAnswerType/FillInTheBlank";
import DescriptiveAnswer from "../components/Test/QuestionAnswerType/DescriptiveAnswer";
import TypingTest from "../components/Typing/typyingTest";
import DifferenceBetween from "../components/Test/QuestionAnswerType/DifferenceBetween";
import MatchTheColumn from "../components/Test/QuestionAnswerType/MatchTheColumn";
import SpeechEvaluation from "../components/Test/QuestionAnswerType/RecordRtc";
import ImageProct from "../components/Test/ImageProctoring";
import LiveProct from "../components/Test/LiveMonitoring";
import AutoSpeech from "../components/Test/QuestionAnswerType/AutoSpeech";
import MultipleFillInTheBlank from "../components/Test/QuestionAnswerType/MultiFillInTheBlank";
import EnterMarksPattern from "../components/Test/QuestionAnswerType/EnterMarksPattern";
import VideoInterview from "../components/Test/QuestionAnswerType/VideoInterview";
import Simulator from "../components/Test/QuestionAnswerType/CodingSimulator";
import { answersBaseURL, resultBaseURL } from "../utils/constants";
import onFinishSaveToMongoFunction from "../utils/onFinishSaveToMongoFunction";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  title: {
    flexGrow: 1,
  },
  overlay: {
    overflow: "inherit",
    position: "initial",
    padding: "0px",
    inset: "40px",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(255, 255, 255)",
    borderRadius: "4px",
    outline: "none",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { examId, setTestData, resumeTest, setResumeTest, closeModal, codeLanguages } = props;

  let testData = props.testData.quesData;
  let examDetail = props.testData.examDetail;
  let token = Cookies.get("token");

  if (testData === undefined || examDetail === undefined) {
    let data = window.localStorage.getItem(examId);
    if (data !== null) {
      let decodedData = decodeURIComponent(atob(decodeURIComponent(data)));
      let stringifyDecodedData = JSON.stringify(decodedData);
      let parsedData = JSON.parse(stringifyDecodedData);
      examDetail = JSON.parse(parsedData)[0].examDetail;
      testData = JSON.parse(parsedData)[0].quesData;
      setTestData({ quesData: JSON.parse(parsedData)[0].quesData, examDetail: JSON.parse(parsedData)[0].examDetail });
    }
  }

  const [value, setValue] = React.useState(0);

  const [questionNo, setQuestionNo] = React.useState(1);

  const [windowSwitchCount, setWindowSwitch] = React.useState(0);

  const [questionChange, setQuestionChange] = React.useState(false);

  const [clearFlag, setClearFlag] = React.useState(false);

  const [checkFlag, setCheckFlag] = React.useState(false);

  const [queryResult, setQueryResult] = React.useState(false);

  const [showTimeOutModel, setTimeoutModelShow] = React.useState(false);
  const [showTimeOutModelOneMinute, setTimeoutModelOneMinuteShow] = React.useState(false);
  const [showProcteringError, setProcteringError] = React.useState(false);

  const [showLastQuestionModal, setLastQuestionModal] = React.useState(false);

  const [showInstruction, setViewInstruction] = React.useState(true);

  const [resumeFlag, setResumeFlag] = React.useState(true);
  const [showThankyou, setThankYou] = React.useState(false);

  const [updateFlag, setUpdateFlag] = React.useState(false);

  const [answerStatus, setAnswerStatus] = React.useState([]);

  const [workFlowExamMsg, setWorkFlowExamMsg] = React.useState("");

  const [showSubmit, setShowSubmit] = React.useState(false);

  const [marksScored, setMarksScored] = React.useState(0);

  const [totalMarks, setTotalMarks] = React.useState(0);

  const [wrongAnswerCount, setWrongAnswerCount] = React.useState(0);

  const [evaluation, setEvaluation] = React.useState({});

  const [webProctoringZero, setWebProcteringZero] = React.useState(false);

  const [showresult, showresultActive] = React.useState(false);
  const [showModel, setModelShow] = React.useState(false);
  const [ProctoringEnd, setProctoringEnd] = React.useState(false);
  const [isAutoSpeechTest, setisAutoSpeechTest] = React.useState(false);
  const [isVideoInterviewTest, setisVideoInterviewTest] = React.useState(false);
  const [isRecording, setIsRecording] = React.useState(false);
  const [isVideoSent, setIsVideoSent] = React.useState(false);
  const [isVideoUploading, setIsVideoUploading] = React.useState(false);
  const [isTimeUp, setIsTimeUp] = React.useState(false);
  const [isRecordingDone, setIsRecordingDone] = React.useState(false);
  const [ImageAlertShowModal, setImageAlertShowModal] = React.useState(false);
  const [AudioAlertShowModal, setAudioAlertShowModal] = React.useState(false);
  const [NoFaceAlertModal, setNoFaceAlertModal] = React.useState(false);
  const [NoMatchAlertModal, setNoMatchAlertModal] = React.useState(false);
  const [MultipleFaceAlertModal, setMultipleFaceAlertModal] = React.useState(false);
  const [MonitoringData, setMonitoringData] = React.useState();
  const [primaryImage, setprimaryImage] = React.useState(null);
  const [isMongoUpdated, setisMongoUpdated] = React.useState(false);
  const [submissionFailure, setSubmissionFailure] = React.useState(false);

  let navFlag = examDetail[0].navflag;
  let showscore = examDetail[0].showscore;
  if (typeof testData === "string") {
    let decodeTestData = decodeURIComponent(atob(decodeURIComponent(testData)));
    let stringifyDecodedData = JSON.stringify(decodeTestData);
    var parseTestData = JSON.parse(stringifyDecodedData);
    testData = JSON.parse(parseTestData);
  }
  let totalAnswered = testData[0]
    ? testData.filter((v, i) => testData[i].buttonStyle == "Answered").length
    : JSON.parse(parseTestData).filter((v, i) => JSON.parse(parseTestData)[i].buttonStyle == "Answered").length;

  let totalQuestion = testData && testData.length;
  const [progress, setProgress] = React.useState(totalAnswered > 0 ? (totalAnswered / totalQuestion) * 100 : 0);

  const mandatory = examDetail[0].answermandatoryflag;

  const [showFinishModal, setFinishModal] = React.useState(false);
  const [showFinishModalDetail, setFinishModalDetail] = React.useState(false);

  const [webProctoring, setWebProct] = React.useState(examDetail[0].webproctoringcount);

  const [disable, setDisable] = React.useState(false);

  const closeInstructionDialogue = () => {
    setViewInstruction(false);
  };

  const closeThankyou = () => {
    setThankYou(false);
    props.closeModal();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    var initLength;
    if (windowSwitchCount > 0) {
      setQuestionNo(questionNo);
    } else {
      if (testData[totalQuestion - 1].buttonStyle == "Answered" || testData[totalQuestion - 1].buttonStyle == "Not Answered") {
        initLength =
          testData &&
          testData.filter(
            (v, i) => testData[i].buttonStyle == "Not Answered" || testData[i].buttonStyle == "Answered" || testData[i].buttonStyle == "Marked Review"
          ).length;
        setQuestionNo(initLength);
      } else {
        initLength =
          testData &&
          testData.filter(
            (v, i) => testData[i].buttonStyle == "Not Answered" || testData[i].buttonStyle == "Answered" || testData[i].buttonStyle == "Marked Review"
          ).length;
        if (initLength === 0) {
          initLength = initLength + 1;
          setQuestionNo(initLength);
        } else {
          setQuestionNo(initLength);
        }
      }
    }

    if (showTimeOutModel) {
      if (isVideoInterviewTest && isRecordingDone) {
        onSubmit("011");
      } else {
        onSubmit("011");
      }
    }

    if (examDetail[0].webproctoringcount - windowSwitchCount < 0 || ProctoringEnd === true) {
      examDetail[0].abort_reason = "photo_mismatch";
      onSubmit("111");
    }
  }, [showTimeOutModel, showTimeOutModelOneMinute, windowSwitchCount, ProctoringEnd]);
  React.useEffect(() => {
    for (let i in testData) {
      if (testData[i].qtypecode === "28") {
        setisAutoSpeechTest(true);
      }
      if (testData[i].qtypecode === "27") {
        setisVideoInterviewTest(true);
      }
    }
    axios
      .post("/test/getMonitoringData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        userexamid: examDetail[0].id,
        instanceid: examDetail[0].instanceid,
      })
      .then((resp) => {
        if (resp.data.result.photo_matching === null) {
          resp.data.result.photo_matching = false;
        }
        if (resp.data.result.speaker_dialect === null) {
          resp.data.result.speaker_dialect = false;
        }
        if (resp.data.result.photo_action === null) {
          resp.data.result.photo_action = "C";
        }
        if (resp.data.result.audio_action === null) {
          resp.data.result.audio_action = "C";
        }
        if (resp.data.result.photo_timer === null) {
          resp.data.result.photo_timer = 120;
        }
        if (resp.data.result.monitoring === true && examDetail[0].status === "001") {
          axios
            .post("/test/sendPrimaryImage", {
              headers: {
                "Access-Control-Allow-Origin": "*",
              },
              userexamid: examDetail[0].id,
            })
            .then((resp) => {
              setprimaryImage(resp.data.primaryImage.imagepath);
            });
        }
        setMonitoringData(resp.data);
      });
  }, []);
  const addNewQuestion = () => {
    //Add only if adaptive
    //setLastQuestionModal(false)
    if (testData && testData[testData.length - 1].buttonStyle !== "Not Visited") {
      let elem = testData;
      let totalmarksAttempt = elem.reduce((a, b) => a + b["qmarks"], 0);

      if (examDetail[0].totalmarkstoattempt - totalmarksAttempt >= examDetail[1]["3"][0].qmarks) {
        if (elem[elem.length - 1].answerStatus) {
          if (elem[elem.length - 1].qdifficultylevelid === "3" || elem[elem.length - 1].qdifficultylevelid === "2") {
            elem.push(examDetail[1]["3"][Math.floor(Math.random() * examDetail[1]["3"].length)]);
            elem[elem.length - 1].index = examDetail[1]["3"].findIndex((x) => x.qid === elem[elem.length - 1].qid);
            examDetail[1]["3"].splice(elem[elem.length - 1].index, 1);
          } else if (elem[elem.length - 1].qdifficultylevelid === "1") {
            elem.push(examDetail[1]["2"][Math.floor(Math.random() * examDetail[1]["2"].length)]);
            elem[elem.length - 1].index = examDetail[1]["2"].findIndex((x) => x.qid === elem[elem.length - 1].qid);
            examDetail[1]["2"].splice(elem[elem.length - 1].index, 1);
          }
        } else {
          if (elem[elem.length - 1].qdifficultylevelid === "3") {
            elem.push(examDetail[1]["2"][Math.floor(Math.random() * examDetail[1]["2"].length)]);
            elem[elem.length - 1].index = examDetail[1]["2"].findIndex((x) => x.qid === elem[elem.length - 1].qid);
            examDetail[1]["2"].splice(elem[elem.length - 1].index, 1);
          } else if (elem[elem.length - 1].qdifficultylevelid === "2" || elem[elem.length - 1].qdifficultylevelid === "1") {
            elem.push(examDetail[1]["1"][Math.floor(Math.random() * examDetail[1]["1"].length)]);
            elem[elem.length - 1].index = examDetail[1]["1"].findIndex((x) => x.qid === elem[elem.length - 1].qid);
            examDetail[1]["1"].splice(elem[elem.length - 1].index, 1);
          }
        }
      } else if (examDetail[0].totalmarkstoattempt - totalmarksAttempt >= examDetail[1]["2"][0].qmarks) {
        if (elem[elem.length - 1].answerStatus) {
          elem.push(examDetail[1]["2"][Math.floor(Math.random() * examDetail[1]["2"].length)]);
          elem[elem.length - 1].index = examDetail[1]["2"].findIndex((x) => x.qid === elem[elem.length - 1].qid);
          examDetail[1]["2"].splice(elem[elem.length - 1].index, 1);
        } else {
          if (elem[elem.length - 1].qdifficultylevelid === "3") {
            elem.push(examDetail[1]["2"][Math.floor(Math.random() * examDetail[1]["2"].length)]);
            elem[elem.length - 1].index = examDetail[1]["2"].findIndex((x) => x.qid === elem[elem.length - 1].qid);
            examDetail[1]["2"].splice(elem[elem.length - 1].index, 1);
          } else if (elem[elem.length - 1].qdifficultylevelid === "2" || elem[elem.length - 1].qdifficultylevelid === "1") {
            elem.push(examDetail[1]["1"][Math.floor(Math.random() * examDetail[1]["1"].length)]);
            elem[elem.length - 1].index = examDetail[1]["1"].findIndex((x) => x.qid === elem[elem.length - 1].qid);
            examDetail[1]["1"].splice(elem[elem.length - 1].index, 1);
          }
        }
      } else if (examDetail[0].totalmarkstoattempt - totalmarksAttempt >= examDetail[1]["1"][0].qmarks) {
        elem.push(examDetail[1]["1"][Math.floor(Math.random() * examDetail[1]["1"].length)]);
        elem[elem.length - 1].index = examDetail[1]["1"].findIndex((x) => x.qid === elem[elem.length - 1].qid);
        examDetail[1]["1"].splice(elem[elem.length - 1].index, 1);
      } else {
        setLastQuestionModal(true);
        setShowSubmit(true);
      }
    }
  };

  if (examDetail[0].status == "000") {
    if (examDetail[0].webproctoringcount == 0) {
      examDetail[0].defaultZeroProctorCheck = true;
    }
  }

  // if(resumeFlag === true){
  //   window.onbeforeunload = function () {
  //     return "Data will be lost if you leave the page, are you sure?";
  //   };
  // }
  // else{
  // window.onbeforeunload = function (event) {
  //      event.preventDefault();
  // };
  // }
  //   useUnload(async(e) => {
  //     // let collectData = {quesData: testData, examDetail: examDetail}
  //     // let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
  //     // if(examDetail[0].status === '000' || examDetail[0].status === '001'){
  //     // window.localStorage.setItem(examId, encode);
  //     // }
  //    let testDataUnload = window.localStorage.getItem(examId)
  //    if(testDataUnload){
  //    let decodedTestData = decodeURIComponent(atob(decodeURIComponent(testDataUnload)))

  //    let parsedData = JSON.parse(decodedTestData);
  //    examDetail = parsedData.examDetail;
  //    testData = parsedData.quesData;

  //     if(parsedData[0]){

  //    let stringifyExamDetail = JSON.stringify(parsedData[0].examDetail)
  //    var encodedExamDetail = encodeURIComponent(btoa(encodeURIComponent(stringifyExamDetail)))

  //    let stringifyQuesData = JSON.stringify(parsedData[0].quesData);
  //    var encodedQuesData = encodeURIComponent(btoa(encodeURIComponent(stringifyQuesData)))

  //     }

  //    let stringifyExamDetailNew = JSON.stringify(parsedData.examDetail);
  //    let encodedExamDetailNew = encodeURIComponent(btoa(encodeURIComponent(stringifyExamDetailNew)))

  //    let stringifyQuesDataNew  = JSON.stringify(parsedData.quesData);
  //    let encodedQuesDataNew = encodeURIComponent(btoa(encodeURIComponent(stringifyQuesDataNew)));

  //     axios.post('/test/answeredQuestions',{
  //      headers: {
  //        'Access-Control-Allow-Origin': '*',
  //      }, examDetail: parsedData[0] ? encodedExamDetail : encodedExamDetailNew ,id: parsedData[0] ? parsedData[0].examDetail[0].id :  parsedData.examDetail[0].id,quesData: parsedData[0] ? encodedQuesData : encodedQuesDataNew
  //    }).then(result => {
  //       // window.localStorage.setItem(examId,result.data.examData)
  //       let decode = decodeURIComponent(atob(decodeURIComponent(result.data.examData)))
  //       let stringifyDecode = JSON.stringify(decode);
  //       let parseExamData = JSON.parse(stringifyDecode);
  //       examDetail = JSON.parse(parseExamData).examDetail;
  //       testData = JSON.parse(parseExamData).quesData;
  //       setTestData({quesData : testData , examDetail: examDetail})
  //       window.localStorage.removeItem(examId);
  //    }).catch(err => {
  //      console.log('error',err);
  //    })
  //   }
  //   });
  // axios.post('/test/updateExamTime', {
  //   headers: {
  //     'Access-Control-Allow-Origin': '*',
  //   },
  //     examDetail: [examDetail[0]]
  //   }).then(
  //   finalRecords => {
  //   // testData = finalRecords.data.quesData;
  //   // setEvaluation(finalRecords.data.result);
  //   }).catch(error=> {
  //     console.log(">>error refresh",error)
  //   })
  // localStorage.setItem(examId, encode)
  // if(examDetail[0].status === '111' || examDetail[0].status === '010' || examDetail[0].status === '011' || examDetail[0].status === '100'){
  //   window.location.reload(true)
  // }else{
  // if(totalAnswered < totalQuestion || totalAnswered === totalQuestion){
  //   examDetail[0].status= '001'
  // }
  // axios.post('/test/updateStatus', {
  //   headers: {
  //     'Access-Control-Allow-Origin': '*',
  //   },
  //     examDetail: [examDetail[0]],
  //     quesData: testData
  //   }).then(
  //   finalRecords => {
  //   // testData = finalRecords.data.quesData;
  //   // setEvaluation(finalRecords.data.result);
  //   }).catch(error=> {
  //   })
  // }
  // });

  // const onSubmit2 = async (status) => {
  //   setQueryResult(true);
  //   onFinishSaveToMongoFunction(examId, examDetail, setWindowSwitch, setTestData, windowSwitchCount, testData, status);
  //   setQueryResult(false);
  //   setUpdateFlag(true);
  //   // if(status === '011'){
  //   //   setTimeoutModelShow(false)
  //   //   setFinishModal(true)
  //   // }
  //   // if(status === '111'){
  //   //   setFinishModal(true)
  //   // }
  //   // console.log(">testData",testData)
  //   // return
  //   examDetail[0].status = status;

  //   let stringifyExamDetail = JSON.stringify(examDetail);
  //   let encodedExamDetail = encodeURIComponent(btoa(encodeURIComponent(stringifyExamDetail)));

  //   let stringifyQuesData = JSON.stringify(testData);
  //   let encodedQuesData = encodeURIComponent(btoa(encodeURIComponent(stringifyQuesData)));
  //   // await axios.post('/test/insertAllAnsweredQts', {
  //   await axios
  //     .post(answersBaseURL + "/v3/answeredqts/insertansweredQts", {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //       examDetail: encodedExamDetail,
  //       id: examDetail[0].id,
  //       quesData: encodedQuesData,
  //     })
  //     .then((response) => {
  //       //  console.log('response', response);
  //     })
  //     .catch((err) => {
  //       console.log("error", err);
  //     });
  //   //  await axios.post('/test/updateStatus', {
  //   await axios
  //     .post(resultBaseURL + "/v3/result/updateStatus", {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //       examDetail: [examDetail[0]],
  //       quesData: testData,
  //     })
  //     .then((finalRecords) => {
  //       setQueryResult(false);
  //       testData = finalRecords.data.quesData;
  //       setEvaluation(finalRecords.data.result);
  //       setWorkFlowExamMsg((finalRecords.data && finalRecords.data.message) || "");
  //       //window.location.reload(false);
  //       setUpdateFlag(true);
  //       if (examDetail[0].thankyouflag) {
  //         setThankYou(true);
  //       } else {
  //         // if((examDetail[0].webproctoringcount - windowSwitchCount) < 0){
  //         //   setFinishModal(true)
  //         // }
  //         if (examDetail[0].showscoreflag) {
  //           showresultActive(true);
  //         }
  //         // if(status === '011'){
  //         //     setFinishModal(true)
  //         // }
  //         // props.closeModal();
  //       }
  //       window.localStorage.removeItem(examId);
  //       setResumeFlag(false);
  //     })
  //     .catch((error) => {
  //       console.log("error on submit", error);
  //     });
  // };

  // const onSubmit = async (status) => {
  //   setUpdateFlag(true);
  //   let userExamId = examDetail[0].id;
  //   examDetail[0].status = status;

  //   // encode data
  //   let stringifyExamDetail = JSON.stringify(examDetail);
  //   let encodedExamDetail = encodeURIComponent(btoa(encodeURIComponent(stringifyExamDetail)));
  //   let stringifyQuesData = JSON.stringify(testData);
  //   let encodedQuesData = encodeURIComponent(btoa(encodeURIComponent(stringifyQuesData)));

  //   try {
  //     setQueryResult(true);

  //     let mongoSaveResponse = await onFinishSaveToMongoFunction(
  //       examId,
  //       examDetail,
  //       setWindowSwitch,
  //       setTestData,
  //       windowSwitchCount,
  //       testData,
  //       status
  //     );

  //     console.log("mongoSaveResponse", mongoSaveResponse);

  //     if (mongoSaveResponse === true) {
  //       // data saved in mongodb, now submit the test to add into queue
  //       const submitResponse = await resultAxios.post(
  //         `/result/submittest/${userExamId}`,
  //         {
  //           examDetail: encodedExamDetail,
  //           questionsData: encodedQuesData,
  //         },
  //         {
  //           headers: {
  //             "Access-Control-Allow-Origin": "*",
  //           },
  //         }
  //       );

  //       console.log("submit response", submitResponse);
  //       if (!submitResponse || submitResponse.status != 200) {
  //         const fallbackResponse = await resultAxios.post(
  //           `/result/fallbacksubmit/${userExamId}`,
  //           { examDetail: encodedExamDetail, questionsData: encodedQuesData },
  //           {
  //             headers: {
  //               "Access-Control-Allow-Origin": "*",
  //             },
  //           }
  //         );

  //         console.log("fallback Response 1", fallbackResponse);

  //         // completed
  //         setQueryResult(false);
  //       } else {
  //         // completed
  //         setQueryResult(false);
  //       }
  //     } else {
  //       // directly send response to backend
  //       const testProcessResponse = await resultAxios.post(
  //         "/result/independent-resultprocess",
  //         {
  //           examDetail: encodedExamDetail,
  //           questionsData: encodedQuesData,
  //         },
  //         {
  //           headers: {
  //             "Access-Control-Allow-Origin": "*",
  //           },
  //         }
  //       );

  //       console.log("independent test reponse 1:", testProcessResponse);

  //       console.log("Test Submitted Successfully");
  //       setQueryResult(false);
  //     }
  //   } catch (error) {
  //     console.log("sumbit error", error);
  //     try {
  //       // call fallback submit api
  //       const fallbackResponse = await resultAxios.post(
  //         `/result/fallbacksubmit/${userExamId}`,
  //         { examDetail: encodedExamDetail, questionsData: encodedQuesData },
  //         {
  //           headers: {
  //             "Access-Control-Allow-Origin": "*",
  //           },
  //         }
  //       );

  //       console.log("fallabck response 2", fallbackResponse);
  //       // completed
  //       setQueryResult(false);
  //     } catch (error) {
  //       console.log("error", error);

  //       try {
  //         // call independent manual function
  //         const testProcessResponse = await resultAxios.post(
  //           "/result/independent-resultprocess",
  //           {
  //             examDetail: encodedExamDetail,
  //             questionsData: encodedQuesData,
  //           },
  //           {
  //             headers: {
  //               "Access-Control-Allow-Origin": "*",
  //             },
  //           }
  //         );

  //         console.log("independent test reponse 2:", testProcessResponse);

  //         console.log("Test Submitted Successfully");
  //         setQueryResult(false);
  //       } catch (error) {
  //         console.log("going to store in a localstorage");
  //         localStorage.setItem(
  //           `test-submit-${userExamId}`,
  //           JSON.stringify({ userExamId: userExamId, examDetail: encodedExamDetail, questionsData: encodedQuesData })
  //         );
  //         setSubmissionFailure(true);
  //         setQueryResult(false);
  //       }
  //     }
  //   }
  // };

  const onSubmit = async (status) => {
    setUpdateFlag(true);
    let userExamId = examDetail[0].id;
    examDetail[0].status = status;

    // encode data
    let stringifyExamDetail = JSON.stringify(examDetail);
    let encodedExamDetail = encodeURIComponent(btoa(encodeURIComponent(stringifyExamDetail)));
    let stringifyQuesData = JSON.stringify(testData);
    let encodedQuesData = encodeURIComponent(btoa(encodeURIComponent(stringifyQuesData)));

    try {
      setQueryResult(true);

      // First try to save data to MongoDB
      let mongoSaveResponse = await onFinishSaveToMongoFunction(
        examId,
        examDetail,
        setWindowSwitch,
        setTestData,
        windowSwitchCount,
        testData,
        status
      );

      console.log("mongoSaveResponse", mongoSaveResponse);

      if (mongoSaveResponse === true) {
        // Data saved in MongoDB, now try to submit the test
        try {
          const submitResponse = await resultAxios.post(
            `/result/submittest/${userExamId}`,
            {
              examDetail: encodedExamDetail,
              questionsData: encodedQuesData,
              submitType: "STANDARD",
            },
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
              },
            }
          );

          console.log("submit response", submitResponse);

          if (!submitResponse || submitResponse.status !== 200) {
            // If /submittest fails, call /fallbacksubmit
            const fallbackResponse = await resultAxios.post(
              `/result/fallbacksubmit/${userExamId}`,
              { examDetail: encodedExamDetail, questionsData: encodedQuesData, submitType: "FALLBACK_ON_STANDARD_FAILED" },
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                },
              }
            );

            console.log("fallback Response 1", fallbackResponse);
          }
        } catch (error) {
          console.log("Submit test error, trying fallback", error);
          // If error occurs, try /fallbacksubmit
          try {
            const fallbackResponse = await resultAxios.post(
              `/result/fallbacksubmit/${userExamId}`,
              { examDetail: encodedExamDetail, questionsData: encodedQuesData, submitType: "FALLBACK_ON_CATCH" },
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                },
              }
            );
            console.log("fallback Response 1", fallbackResponse);
          } catch (fallbackError) {
            console.log("Fallback submission failed", fallbackError);
          }
        }
      } else {
        // If MongoDB save fails, send data to /independent-resultprocess
        try {
          const testProcessResponse = await axiosInstance.post(
            resultBaseURL+"/independent-resultprocess",
            {
              examDetail: encodedExamDetail,
              questionsData: encodedQuesData,
              submitType: "MONGODB_SKIPPED",
            },
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
              },
            }
          );

          console.log("independent test response 1:", testProcessResponse);
        } catch (independentError) {
          console.log("Independent result process failed", independentError);

          // save in localstorage
          localStorage.setItem(
            `test-submit-${userExamId}`,
            JSON.stringify({
              userExamId: userExamId,
              examDetail: encodedExamDetail,
              questionsData: encodedQuesData,
            })
          );
          setSubmissionFailure(true);
        }
      }
    } catch (error) {
      console.log("Mongo save error", error);
      // If saving to MongoDB fails, call /independent-resultprocess
      try {
        const testProcessResponse = await resultAxios.post(
          "/result/independent-resultprocess",
          {
            examDetail: encodedExamDetail,
            questionsData: encodedQuesData,
            submitType: "MONGODB_SKIPPED_CATCH",
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        console.log("independent test response 2:", testProcessResponse);
      } catch (independentError) {
        console.log("Independent result process failed again", independentError);

        // If everything fails, store data in localStorage
        try {
          localStorage.setItem(
            `test-submit-${userExamId}`,
            JSON.stringify({
              userExamId: userExamId,
              examDetail: encodedExamDetail,
              questionsData: encodedQuesData,
            })
          );
          setSubmissionFailure(true);
        } catch (storageError) {
          console.log("Failed to store data in localStorage", storageError);
        }
      }
    } finally {
      // Completed the submission process
      setQueryResult(false);
    }
  };

  const onSubmitWORKING = async (status) => {
    try {
      // save into mongodb
      setQueryResult(true);

      let mongoSaveResponse = await onFinishSaveToMongoFunction(
        examId,
        examDetail,
        setWindowSwitch,
        setTestData,
        windowSwitchCount,
        testData,
        status
      );

      console.log("mongoSaveResponse : ", mongoSaveResponse);

      if (mongoSaveResponse === true) {
        // setMongoSaveFailure(false);
      } else {
        console.log("Inside else ...");
        // setMongoSaveFailure(true);
      }

      // modal that tells to wait
      setQueryResult(false);

      let userExamId = examDetail[0].id;

      setUpdateFlag(true);
      examDetail[0].status = status;

      // encode data
      let stringifyExamDetail = JSON.stringify(examDetail);
      let encodedExamDetail = encodeURIComponent(btoa(encodeURIComponent(stringifyExamDetail)));

      let stringifyQuesData = JSON.stringify(testData);
      let encodedQuesData = encodeURIComponent(btoa(encodeURIComponent(stringifyQuesData)));

      await resultAxios
        .post(
          `/result/submittest/${userExamId}`,
          {
            examDetail: encodedExamDetail,
            questionsData: encodedQuesData,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then(async (response) => {
          console.log("submit response :", response.status);
          if (response.status === 500 || response.status === 400 || response.status === 502 || response.status === 429 || response.status === 204) {
            let response = await resultAxios.post(
              `/result/fallbacksubmit/${userExamId}`,
              { examDetail: encodedExamDetail, questionsData: encodedQuesData },
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                },
              }
            );

            console.log("fallback response", response);
            window.localStorage.removeItem(examId);
          } else {
            console.log("got 200");
            window.localStorage.removeItem(examId);
          }
        })
        .catch(async (error) => {
          console.log("submit error: ", error);
          try {
            let response = await resultAxios.post(
              `/result/fallbacksubmit/${userExamId}`,
              { examDetail: encodedExamDetail, questionsData: encodedQuesData },
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                },
              }
            );

            console.log("fallback response", response);
            window.localStorage.removeItem(examId);
          } catch (error) {
            console.log("fallback submit err:", error);
          }
        });
    } catch (error) {
      console.log("OnSubmit error: ", error);
    }
  };

  React.useEffect(() => {
    window.onkeyup = function (e) {
      if (e.metaKey || e.shiftKey) {
        if (document.getElementById("main-body") !== null) {
          document.getElementById("main-body").style = "";
        } else if (document.getElementById("main-body-two") !== null) {
          document.getElementById("main-body-two").style = "";
        }
      }
      if (e.keyCode === 91 || e.keyCode === 92 || e.keyCode === 44) {
        if (document.getElementById("main-body") !== null) {
          document.getElementById("main-body").style = "";
        } else if (document.getElementById("main-body-two") !== null) {
          document.getElementById("main-body-two").style = "";
        }
      }
      if (e.key === "Control") {
        if (document.getElementById("main-body") !== null) {
          document.getElementById("main-body").style = "";
        } else if (document.getElementById("main-body-two") !== null) {
          document.getElementById("main-body-two").style = "";
        }
      }
    };

    window.onclick = function (e) {
      if (document.getElementById("main-body") !== null) {
        document.getElementById("main-body").style = "";
      } else if (document.getElementById("main-body-two") !== null) {
        document.getElementById("main-body-two").style = "";
      }
    };

    window.onkeydown = function (e) {
      if (e.metaKey && e.shiftKey) {
        if (document.getElementById("main-body") !== null) {
          document.getElementById("main-body").style.filter = "blur(5px)";
          // return false;
        } else if (document.getElementById("main-body-two") !== null) {
          document.getElementById("main-body-two").style.filter = "blur(5px)";
          // return false;
        }
      }
      if (e.metaKey && e.keyCode == 80) {
        return false;
      }
      if ((e.keyCode === 91 || e.keyCode === 92) && e.keyCode === 44) {
        if (document.getElementById("main-body") !== null) {
          document.getElementById("main-body").style.filter = "blur(5px)";
          // return false;
        } else if (document.getElementById("main-body-two") !== null) {
          document.getElementById("main-body-two").style.filter = "blur(5px)";
          // return false;
        }
      }
      if (e.key === "Control") {
        if (document.getElementById("main-body") !== null) {
          document.getElementById("main-body").style.filter = "blur(5px)";
        } else if (document.getElementById("main-body-two") !== null) {
          document.getElementById("main-body-two").style.filter = "blur(5px)";
        }
      }
      if (e.metaKey && e.shiftKey && e.key === "4") {
        return false;
      }
    };
  });

  return (
    <>
      {showTimeOutModel ? (
        <TimeOutModal
          updateFlag={updateFlag}
          showTimeOutModel={showTimeOutModel}
          setTimeoutModelShow={setTimeoutModelShow}
          setFinishModal={setFinishModal}
          onSubmit={onSubmit}
          result={evaluation}
          showResult={showresult}
          showscore={examDetail[0].showscoreflag}
          examId={examId}
          examDetail={examDetail}
          setWindowSwitch={setWindowSwitch}
          setTestData={setTestData}
          windowSwitchCount={windowSwitchCount}
          testData={testData}
        />
      ) : showInstruction ? (
        <>
          <Dialog
            disableEscapeKeyDown
            PaperProps={{ style: { background: "#e5e5e9" } }}
            fullScreen
            open={showInstruction}
            onClose={closeInstructionDialogue}
            TransitionComponent={Transition}
          >
            <InstructionPage
              examDetail={examDetail}
              setViewInstruction={setViewInstruction}
              themeType={props.themeType}
              changeTheme={props.changeTheme}
              testData={testData}
              setTestData={setTestData}
              MonitoringData={MonitoringData}
              isAutoSpeechTest={isAutoSpeechTest}
              isVideoInterviewTest={isVideoInterviewTest}
            />
          </Dialog>
        </>
      ) : showThankyou ? (
        <ThankyouPage
          examDetail={examDetail}
          closeThankyou={closeThankyou}
          themeType={props.themeType}
          changeTheme={props.changeTheme}
          evaluation={evaluation}
        />
      ) : showFinishModal ? (
        showThankyou ? (
          <ThankyouPage
            examDetail={examDetail}
            closeThankyou={closeThankyou}
            themeType={props.themeType}
            changeTheme={props.changeTheme}
            evaluation={evaluation}
          />
        ) : (
          <FinishModal
            workFlowExamMsg={workFlowExamMsg}
            setWorkFlowExamMsg={setWorkFlowExamMsg}
            queryResult={queryResult}
            showFinishModal={showFinishModal}
            onSubmit={onSubmit}
            result={evaluation}
            showResult={showresult}
            showscore={examDetail[0].showscoreflag}
            setResumeFlag={setResumeFlag}
            examId={examId}
            examDetail={examDetail}
            setWindowSwitch={setWindowSwitch}
            setTestData={setTestData}
            windowSwitchCount={windowSwitchCount}
            testData={testData}
            submissionFailure={submissionFailure}
          />
        )
      ) : (
        // workFlowExamMsg !== '' ? < WorkFlowMessageModal workFlowExamMsg = {workFlowExamMsg} setWorkFlowExamMsg = {setWorkFlowExamMsg}/> :
        <div id="main-body" style={{ background: "#E5E6E9" }}>
          {(testData[questionNo - 1].qtypecode != "23" ||
            testData[questionNo - 1].qtypecode != "25" ||
            testData[questionNo - 1].qtypecode != "27" ||
            testData[questionNo - 1].qtypecode != "26" ||
            testData[questionNo - 1].qtypecode != "19" ||
            testData[questionNo - 1].qtypecode != "29") &&
          examDetail[0].defaultZeroProctorCheck != true ? (
            webProctoring > 0 || examDetail[0].webproctoringzero === true ? (
              <WindowFocusHandler
                updateFlag={updateFlag}
                showModel={showModel}
                setModelShow={setModelShow}
                setWindowSwitch={setWindowSwitch}
                windowSwitchCount={windowSwitchCount}
                themeType={props.themeType}
                examId={examId}
                examDetail={examDetail}
                testData={testData}
                setTestData={setTestData}
                closeModal={props.closeModal}
                onSubmit={onSubmit}
                result={evaluation}
                showResult={showresult}
                setWebProcteringZero={setWebProcteringZero}
                webProctoringZero={webProctoringZero}
                webProctoring={webProctoring}
                showscore={examDetail[0].showscoreflag}
              />
            ) : (
              ""
            )
          ) : (
            ""
          )}

          {showFinishModalDetail ? (
            <FinishModalDetail
              examDetail={examDetail}
              testData={testData}
              setFinishModal={setFinishModal}
              setFinishModalDetail={setFinishModalDetail}
              showFinishModalDetail={showFinishModalDetail}
              onSubmit={onSubmit}
            />
          ) : (
            ""
          )}
          {showTimeOutModelOneMinute ? (
            <TimeOutModalOneMinute
              showTimeOutModelOneMinute={showTimeOutModelOneMinute}
              setTimeoutModelOneMinuteShow={setTimeoutModelOneMinuteShow}
              onSubmit={onSubmit}
            />
          ) : showLastQuestionModal ? (
            <LastQuestionModal showLastQuestionModal={showLastQuestionModal} setLastQuestionModal={setLastQuestionModal} />
          ) : (
            ""
          )}
          {/* {
                           showProcteringError ?
                           <ProctoringError showProcteringError={showProcteringError} setProcteringError={setProcteringError}/>
                           :
                           ""

                         } */}
          <div className="container-fluid main-wrap">
            <TestAppBar
              showModel={showModel}
              ImageAlertShowModal={ImageAlertShowModal}
              AudioAlertShowModal={AudioAlertShowModal}
              NoFaceAlertModal={NoFaceAlertModal}
              NoMatchAlertModal={NoMatchAlertModal}
              MultipleFaceAlertModal={MultipleFaceAlertModal}
              showFinishModalDetail={showFinishModalDetail}
              value={value}
              onSubmit={onSubmit}
              changeTheme={props.changeTheme}
              themeType={props.themeType}
              setQuestionNo={setQuestionNo}
              setViewInstruction={setViewInstruction}
              examDetail={examDetail}
              navFlag={navFlag}
              setQuestionChange={setQuestionChange}
              testData={testData}
              questionNo={questionNo}
              setTimeoutModelShow={setTimeoutModelShow}
              setTimeoutModelOneMinuteShow={setTimeoutModelOneMinuteShow}
              isVideoUploading={isVideoUploading}
              isTimeUp={isTimeUp}
              setIsTimeUp={setIsTimeUp}
              isRecordingDone={isRecordingDone}
              setIsRecordingDone={setIsRecordingDone}
              isVideoInterviewTest={isVideoInterviewTest}
            />

            <Grid item xs={1} />
            {/* {
                        examDetail[0].livemonitoringflag ?<LiveProct examDetail={examDetail}/> : <></>
                      } */}
            {examDetail[0].monitoringflag ? (
              <ImageProct
                setProcteringError={setProcteringError}
                examDetail={examDetail}
                setImageAlertShowModal={setImageAlertShowModal}
                ImageAlertShowModal={ImageAlertShowModal}
                ProctoringEnd={ProctoringEnd}
                setProctoringEnd={setProctoringEnd}
                setAudioAlertShowModal={setAudioAlertShowModal}
                AudioAlertShowModal={AudioAlertShowModal}
                NoFaceAlertModal={NoFaceAlertModal}
                setNoFaceAlertModal={setNoFaceAlertModal}
                NoMatchAlertModal={NoMatchAlertModal}
                setNoMatchAlertModal={setNoMatchAlertModal}
                MultipleFaceAlertModal={MultipleFaceAlertModal}
                setMultipleFaceAlertModal={setMultipleFaceAlertModal}
                MonitoringData={MonitoringData}
                primaryImage={primaryImage}
                setprimaryImage={setprimaryImage}
                examId={examId}
                setWindowSwitch={setWindowSwitch}
                setTestData={setTestData}
                windowSwitchCount={windowSwitchCount}
                testData={testData}
                isMongoUpdated={isMongoUpdated}
                setisMongoUpdated={setisMongoUpdated}
              />
            ) : (
              <></>
            )}
            <div className="row">
              <div className="container  qa-wrap" style={{ top: "25px", marginTop: "60px" }}>
                <div className="qa-overlay" id="main-layout"></div>

                <div className="col-md-12 question-box" id="layout-2">
                  {/* <ProgressBar bgcolor="#8c59b3" completed={progress}/> */}
                  {/* {console.log('testData[questionNo - 1]',testData[questionNo - 1])} */}
                  {testData[questionNo - 1].qtypecode === "12" || testData[questionNo - 1].qtypecode === "26" ? (
                    <FillInTheBlank
                      testData={testData}
                      questionNo={questionNo}
                      clearFlag={clearFlag}
                      setClearFlag={setClearFlag}
                      examId={examId}
                      setCheckFlag={setCheckFlag}
                      examDetail={examDetail}
                      answerStatus={answerStatus}
                      setAnswerStatus={setAnswerStatus}
                      marksScored={marksScored}
                      setMarksScored={setMarksScored}
                      totalMarks={totalMarks}
                      setTotalMarks={setTotalMarks}
                      wrongAnswerCount={wrongAnswerCount}
                      setWrongAnswerCount={setWrongAnswerCount}
                      disable={disable}
                      setDisable={setDisable}
                      setTestData={setTestData}
                      resumeFlag={resumeFlag}
                      setResumeFlag={setResumeFlag}
                      windowSwitchCount={windowSwitchCount}
                      setWindowSwitch={setWindowSwitch}
                      setWebProcteringZero={setWebProcteringZero}
                    />
                  ) : testData[questionNo - 1].qtypecode === "17" ? (
                    <EnterMarksPattern
                      testData={testData}
                      questionNo={questionNo}
                      clearFlag={clearFlag}
                      setClearFlag={setClearFlag}
                      examId={examId}
                      setCheckFlag={setCheckFlag}
                      examDetail={examDetail}
                      answerStatus={answerStatus}
                      setAnswerStatus={setAnswerStatus}
                      marksScored={marksScored}
                      setMarksScored={setMarksScored}
                      totalMarks={totalMarks}
                      setTotalMarks={setTotalMarks}
                      wrongAnswerCount={wrongAnswerCount}
                      setWrongAnswerCount={setWrongAnswerCount}
                      disable={disable}
                      mandatory={mandatory}
                      setDisable={setDisable}
                      setTestData={setTestData}
                      windowSwitchCount={windowSwitchCount}
                      setWindowSwitch={setWindowSwitch}
                    />
                  ) : testData[questionNo - 1].qtypecode === "2" ? (
                    <MultipleSelectAnswer
                      testData={testData}
                      questionNo={questionNo}
                      clearFlag={clearFlag}
                      setClearFlag={setClearFlag}
                      examId={examId}
                      setCheckFlag={setCheckFlag}
                      examDetail={examDetail}
                      answerStatus={answerStatus}
                      setAnswerStatus={setAnswerStatus}
                      disable={disable}
                      setDisable={setDisable}
                      setTestData={setTestData}
                      windowSwitchCount={windowSwitchCount}
                      setWindowSwitch={setWindowSwitch}
                    />
                  ) : testData[questionNo - 1].qtypecode === "16" ||
                    testData[questionNo - 1].qtypecode === "3" ||
                    testData[questionNo - 1].qtypecode === "14" ||
                    testData[questionNo - 1].qtypecode === "18" ||
                    testData[questionNo - 1].qtypecode === "24" ? (
                    <MultipleanswerAllCorrect
                      testData={testData}
                      questionNo={questionNo}
                      clearFlag={clearFlag}
                      setClearFlag={setClearFlag}
                      examId={examId}
                      setCheckFlag={setCheckFlag}
                      examDetail={examDetail}
                      answerStatus={answerStatus}
                      setAnswerStatus={setAnswerStatus}
                      disable={disable}
                      setDisable={setDisable}
                      setTestData={setTestData}
                      windowSwitchCount={windowSwitchCount}
                      setWindowSwitch={setWindowSwitch}
                    />
                  ) : testData[questionNo - 1].qtypecode === "1" ? (
                    <Passage
                      testData={testData}
                      questionNo={questionNo}
                      clearFlag={clearFlag}
                      setClearFlag={setClearFlag}
                      examId={examId}
                      setCheckFlag={setCheckFlag}
                      examDetail={examDetail}
                      answerStatus={answerStatus}
                      setAnswerStatus={setAnswerStatus}
                      disable={disable}
                      setDisable={setDisable}
                      setTestData={setTestData}
                      windowSwitchCount={windowSwitchCount}
                      setWindowSwitch={setWindowSwitch}
                    />
                  ) : testData[questionNo - 1].qtypecode === "4" ||
                    testData[questionNo - 1].qtypecode === "5" ||
                    testData[questionNo - 1].qtypecode === "6" ||
                    testData[questionNo - 1].qtypecode === "7" ||
                    testData[questionNo - 1].qtypecode === "8" ||
                    testData[questionNo - 1].qtypecode === "9" ||
                    testData[questionNo - 1].qtypecode === "10" ||
                    testData[questionNo - 1].qtypecode === "25" ? (
                    <DescriptiveAnswer
                      testData={testData}
                      questionNo={questionNo}
                      clearFlag={clearFlag}
                      setClearFlag={setClearFlag}
                      examId={examId}
                      setCheckFlag={setCheckFlag}
                      examDetail={examDetail}
                      answerStatus={answerStatus}
                      setAnswerStatus={setAnswerStatus}
                      marksScored={marksScored}
                      setMarksScored={setMarksScored}
                      totalMarks={totalMarks}
                      setTotalMarks={setTotalMarks}
                      wrongAnswerCount={wrongAnswerCount}
                      setWrongAnswerCount={setWrongAnswerCount}
                      disable={disable}
                      setDisable={setDisable}
                      setTestData={setTestData}
                      windowSwitchCount={windowSwitchCount}
                      setWindowSwitch={setWindowSwitch}
                    />
                  ) : testData[questionNo - 1].qtypecode === "19" || testData[questionNo - 1].qtypecode === "29" ? (
                    <TypingTest
                      testData={testData}
                      questionNo={questionNo}
                      clearFlag={clearFlag}
                      setClearFlag={setClearFlag}
                      examId={examId}
                      setCheckFlag={setCheckFlag}
                      examDetail={examDetail}
                      answerStatus={answerStatus}
                      setAnswerStatus={setAnswerStatus}
                      disable={disable}
                      setDisable={setDisable}
                      setTestData={setTestData}
                      windowSwitchCount={windowSwitchCount}
                      setWindowSwitch={setWindowSwitch}
                    />
                  ) : testData[questionNo - 1].qtypecode === "15" ? (
                    <DifferenceBetween
                      testData={testData}
                      questionNo={questionNo}
                      clearFlag={clearFlag}
                      setClearFlag={setClearFlag}
                      examId={examId}
                      setCheckFlag={setCheckFlag}
                      examDetail={examDetail}
                      answerStatus={answerStatus}
                      setAnswerStatus={setAnswerStatus}
                      marksScored={marksScored}
                      setMarksScored={setMarksScored}
                      totalMarks={totalMarks}
                      setTotalMarks={setTotalMarks}
                      wrongAnswerCount={wrongAnswerCount}
                      setWrongAnswerCount={setWrongAnswerCount}
                      disable={disable}
                      setDisable={setDisable}
                      setTestData={setTestData}
                      windowSwitchCount={windowSwitchCount}
                      setWindowSwitch={setWindowSwitch}
                    />
                  ) : testData[questionNo - 1].qtypecode === "13" ? (
                    <MatchTheColumn
                      testData={testData}
                      questionNo={questionNo}
                      clearFlag={clearFlag}
                      setClearFlag={setClearFlag}
                      examId={examId}
                      setCheckFlag={setCheckFlag}
                      examDetail={examDetail}
                      answerStatus={answerStatus}
                      setAnswerStatus={setAnswerStatus}
                      marksScored={marksScored}
                      setMarksScored={setMarksScored}
                      totalMarks={totalMarks}
                      setTotalMarks={setTotalMarks}
                      wrongAnswerCount={wrongAnswerCount}
                      setWrongAnswerCount={setWrongAnswerCount}
                      disable={disable}
                      setDisable={setDisable}
                      setTestData={setTestData}
                      windowSwitchCount={windowSwitchCount}
                      setWindowSwitch={setWindowSwitch}
                    />
                  ) : testData[questionNo - 1].qtypecode === "23" || testData[questionNo - 1].qtypecode === "31" ? (
                    <SpeechEvaluation
                      testData={testData}
                      questionNo={questionNo}
                      clearFlag={clearFlag}
                      setClearFlag={setClearFlag}
                      examId={examId}
                      setCheckFlag={setCheckFlag}
                      examDetail={examDetail}
                      answerStatus={answerStatus}
                      setAnswerStatus={setAnswerStatus}
                      marksScored={marksScored}
                      setMarksScored={setMarksScored}
                      totalMarks={totalMarks}
                      setTotalMarks={setTotalMarks}
                      wrongAnswerCount={wrongAnswerCount}
                      setWrongAnswerCount={setWrongAnswerCount}
                      disable={disable}
                      setDisable={setDisable}
                      setTestData={setTestData}
                      windowSwitchCount={windowSwitchCount}
                    />
                  ) : testData[questionNo - 1].qtypecode === "27" ? (
                    <VideoInterview
                      testData={testData}
                      questionNo={questionNo}
                      clearFlag={clearFlag}
                      setClearFlag={setClearFlag}
                      examId={examId}
                      setCheckFlag={setCheckFlag}
                      examDetail={examDetail}
                      answerStatus={answerStatus}
                      setAnswerStatus={setAnswerStatus}
                      marksScored={marksScored}
                      setMarksScored={setMarksScored}
                      totalMarks={totalMarks}
                      setTotalMarks={setTotalMarks}
                      wrongAnswerCount={wrongAnswerCount}
                      setWrongAnswerCount={setWrongAnswerCount}
                      disable={disable}
                      setDisable={setDisable}
                      setTestData={setTestData}
                      windowSwitchCount={windowSwitchCount}
                      isRecording={isRecording}
                      setIsRecording={setIsRecording}
                      isVideoSent={isVideoSent}
                      setIsVideoSent={setIsVideoSent}
                      isVideoUploading={isVideoUploading}
                      setIsVideoUploading={setIsVideoUploading}
                      isTimeUp={isTimeUp}
                      setIsTimeUp={setIsTimeUp}
                      isRecordingDone={isRecordingDone}
                      setIsRecordingDone={setIsRecordingDone}
                    />
                  ) : testData[questionNo - 1].qtypecode === "28" ? (
                    <AutoSpeech
                      testData={testData}
                      questionNo={questionNo}
                      clearFlag={clearFlag}
                      setClearFlag={setClearFlag}
                      examId={examId}
                      setCheckFlag={setCheckFlag}
                      examDetail={examDetail}
                      answerStatus={answerStatus}
                      setAnswerStatus={setAnswerStatus}
                      marksScored={marksScored}
                      setMarksScored={setMarksScored}
                      totalMarks={totalMarks}
                      setTotalMarks={setTotalMarks}
                      wrongAnswerCount={wrongAnswerCount}
                      setWrongAnswerCount={setWrongAnswerCount}
                      disable={disable}
                      setDisable={setDisable}
                      isRecording={isRecording}
                      setIsRecording={setIsRecording}
                      isVideoSent={isVideoSent}
                      setIsVideoSent={setIsVideoSent}
                      isVideoUploading={isVideoUploading}
                      setIsVideoUploading={setIsVideoUploading}
                      setTestData={setTestData}
                      windowSwitchCount={windowSwitchCount}
                      setWindowSwitch={setWindowSwitch}
                    />
                  ) : testData[questionNo - 1].qtypecode === "21" ? (
                    <MultipleFillInTheBlank
                      testData={testData}
                      questionNo={questionNo}
                      clearFlag={clearFlag}
                      setClearFlag={setClearFlag}
                      examId={examId}
                      setCheckFlag={setCheckFlag}
                      examDetail={examDetail}
                      answerStatus={answerStatus}
                      setAnswerStatus={setAnswerStatus}
                      disable={disable}
                      setDisable={setDisable}
                      setTestData={setTestData}
                      windowSwitchCount={windowSwitchCount}
                      setWindowSwitch={setWindowSwitch}
                      setWebProcteringZero={setWebProcteringZero}
                    />
                  ) : testData[questionNo - 1].qtypecode === "20" ? (
                    <Simulator
                      testData={testData}
                      questionNo={questionNo}
                      clearFlag={clearFlag}
                      setClearFlag={setClearFlag}
                      examId={examId}
                      setCheckFlag={setCheckFlag}
                      examDetail={examDetail}
                      answerStatus={answerStatus}
                      setAnswerStatus={setAnswerStatus}
                      marksScored={marksScored}
                      setMarksScored={setMarksScored}
                      totalMarks={totalMarks}
                      setTotalMarks={setTotalMarks}
                      wrongAnswerCount={wrongAnswerCount}
                      setWrongAnswerCount={setWrongAnswerCount}
                      disable={disable}
                      setDisable={setDisable}
                      setTestData={setTestData}
                      windowSwitchCount={windowSwitchCount}
                      setWindowSwitch={setWindowSwitch}
                      codeLanguages={codeLanguages}
                    />
                  ) : (
                    <></>
                  )}
                  {/* <hr></hr>
                              {/* <TimePerQuestion testData={testData} questionNo={questionNo}
                          questionChange={questionChange} setQuestionChange={setQuestionChange} />
                          {/* <MultipleSelectAnswer testData={testData} questionNo={questionNo}
                          clearFlag={clearFlag} setClearFlag={setClearFlag} /> */}
                  <Proceed
                    showModel={showModel}
                    isRecording={isRecording}
                    setIsRecording={setIsRecording}
                    isVideoSent={isVideoSent}
                    setIsVideoSent={setIsVideoSent}
                    isVideoUploading={isVideoUploading}
                    setIsVideoUploading={setIsVideoUploading}
                    setModelShow={setModelShow}
                    showScore={showscore}
                    showThankyou={showThankyou}
                    setLastQuestionModal={setLastQuestionModal}
                    webProctoring={webProctoring}
                    setProgress={setProgress}
                    progress={progress}
                    questionChange={questionChange}
                    showSubmit={showSubmit}
                    setFinishModal={setFinishModal}
                    setFinishModalDetail={setFinishModalDetail}
                    questionNo={questionNo}
                    setQuestionNo={setQuestionNo}
                    setClearFlag={setClearFlag}
                    testData={testData}
                    examId={examId}
                    checkFlag={checkFlag}
                    setCheckFlag={setCheckFlag}
                    setQuestionChange={setQuestionChange}
                    clearFlag={clearFlag}
                    navFlag={navFlag}
                    selectedOption={
                      testData[questionNo - 1].qtypecode === "28" ||
                      testData[questionNo - 1].qtypecode === "20" ||
                      testData[questionNo - 1].qtypecode === "26" ||
                      testData[questionNo - 1].qtypecode === "12" ||
                      testData[questionNo - 1].qtypecode === "6"
                        ? testData[questionNo - 1].descriptiveText
                        : testData[questionNo - 1].selectedChoiceId
                    }
                    examDetail={examDetail}
                    addNewQuestion={addNewQuestion}
                    disable={disable}
                    mandatory={mandatory}
                    setDisable={setDisable}
                    windowSwitchCount={windowSwitchCount}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <QuestionNoList themeType={props.themeType} testData={testData}
                          setQuestionNo={setQuestionNo} questionNo={questionNo}
                          setQuestionChange={setQuestionChange} navFlag={navFlag} />

                          <br />

                          <Divider style={{color: 'white'}} />

                          <AnswerUserInfo value={value} />
                      */}
        </div>
      )}
    </>
  );
};
