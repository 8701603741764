import axios from './axiosConfig';
//import useUnload from "../components/UseUnload";
import saveToMongoFunction from './saveToMongoFunction';

const onWindowReload  = async(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData) => {
  window.onbeforeunload = function () {
    saveToMongoFunction(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData)
  
    return "Data will be lost if you leave the page, are you sure?";
  };
 

}

export default onWindowReload;