import React from 'react';
import clsx from "clsx";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Popover from "@mui/material/Popover";
// import Hidden from "@mui/material/Hidden";
// import InputIcon from "@mui/icons-material/Input";
import { makeStyles, useTheme } from "@mui/styles";
// import InputBase from '@mui/material/InputBase';
import PropTypes from 'prop-types';
// import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
// import MailIcon from '@mui/icons-material/Mail';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import Brightness from '@mui/icons-material/Brightness7';
// import Dark from '@mui/icons-material/Brightness4';
import { useSpring, animated } from 'react-spring';
// import List from './List';
import MobileList from './ListMobile';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
// import { WEB_URL } from '../utils/constants';
import {Link} from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ahGreenDotLogo from "./../images/assesshub_logo_green_dot.svg";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding:'3px 5px 0px 5px',
  },
  grow: {
    flexGrow: 1,
  },title: {
    flexGrow: 1,  
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  title1: {
    position: 'relative',
    flexGrow: 1,  
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    //backgroundColor: fade(theme.palette.common.white, 0.15),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,  
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    title: {
      flexGrow: 1.5,  
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
  }, 
  root: {
      display: "flex"
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: 36
    },
    hide: {
      display: "none"
    },
    
    toolbar: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    signOutButton: {
      marginLeft: theme.spacing(1)
    }
  }));



  
  const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
      from: { opacity: 0 },
      to: { opacity: open ? 1 : 0 },
      onStart: () => {
        if (open && onEnter) {
          onEnter();
        }
      },
      onRest: () => {
        if (!open && onExited) {
          onExited();
        }
      },
    });
  
    return (
      <animated.div ref={ref} style={style} {...other}>
        {children}
      </animated.div>
    );
  });

  Fade.propTypes = {
    children: PropTypes.element,
    in: PropTypes.bool,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
  };

export default (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
  

    const openlog = Boolean(anchorEl);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
  
    const handlePopoverOpen2 = (event) => {
      setAnchorEl2(event.currentTarget);
    };
  
    const handlePopoverClose2 = () => {
      setAnchorEl2(null);
    };
  

    const openlog2 = Boolean(anchorEl2);
    //const [open, setOpen] = React.useState(false);
    const {open, handleDrawerOpen, handleDrawerClose, history,testData} = props;
    // const handleDrawerOpen = () => {
    //     setOpen(true);
    // };
    
    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };
    const [anchorelm, setAnchorElm] = React.useState(null);
    //const [morelm, setMorelm] = React.useState(null);
    const [isShown, setIsShown] = React.useState(false);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorelm);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const mobId = isMobileMenuOpen ? 'spring-popper' : undefined;
    const [openProf, openProfileSet] = React.useState(false);
    const handleProfileMenuOpen = event => {
     
      setAnchorElm(anchorelm ? null : event.currentTarget);
      setMobileMoreAnchorEl(null);

      //setAnchorElm(event.currentTarget);
    };
    
  const [userOrgData, setUserOrgData] = React.useState('');
  const [org_name, setCurrentOrg] = React.useState('');
  const [orgId, setOrgId] = React.useState('');
  const [typeValue, setType] = React.useState([]);
  const [buttonArray, setButtonArray] = React.useState([]);
  const [pending, setPending] = React.useState([]);
  const [started, setStarted] = React.useState([]);
  const [typeButton, setTypeButton] = React.useState('');

    const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
    };
  
    const handleMenuClose = () => {
      setAnchorElm(null);
      handleMobileMenuClose();
    };
  
    const handleMobileMenuOpen = event => {
      setMobileMoreAnchorEl(mobileMoreAnchorEl ? null : event.currentTarget);
      //setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleClickProf = () => {
      openProfileSet(prev => !prev);
    };
  
    const handleClickAwayProf = () => {
      openProfileSet(false);
    };

    React.useEffect(() => {    
    },[]);

    const openProfile = Boolean(anchorelm);
    const id = openProfile ? 'spring-popper' : undefined;
  
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        anchorelm={anchorelm}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        // PaperProps={{
        //   style: {
        //     top:'-238px',
        //     transform: 'translateX(-77%) translateY(32%)',
        //   }
        // }}
      >
        <MenuItem  onClick={handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      </Menu>
    );


  
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
       <MobileList themeType={props.themeType} history={history} changeTheme={props.changeTheme} />
      
    );
    return (
      <div className={classes.grow}>
      <AppBar style={{background:"#ffffff"}} position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open
      })}>
      <Toolbar>
        <IconButton
          // color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: open
          })}
        >
          <MenuIcon   />
        </IconButton>
       
        {/* <Typography className={classes.title} variant="h6" noWrap>
          
        </Typography> */}
        <div className="container" style={{textAlign:"center",paddingTop:"0px"}}>
          <img className="center-block" src={ahGreenDotLogo} style={{ height: "35px" }} alt="Assesshub_logo" />
        </div>

        <div className={classes.sectionDesktop}>
        <Link to='/v2/user/logout' style={{textDecoration: 'none',color:"#fff",cursor : 'pointer',
        }}>
          <IconButton
            edge="end"
            
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose} 
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            // color="inherit"
            aria-describedby={id}
          >

            <ExitToAppIcon />
          </IconButton>
          <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openlog}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <h6 style={{marginTop:'6px'}}>Click here to logout</h6>
      </Popover>
          
      </Link>
        <Link to='/v2/user/profile' style={{textDecoration: 'none',color:"#fff",cursor : 'pointer',
        }}>
          <IconButton
            edge="end"

          onMouseEnter={handlePopoverOpen2}
          onMouseLeave={handlePopoverClose2} 
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            // color="inherit"
            aria-describedby={id}
          >
            <AccountCircle />

          </IconButton>

          <Popover
        id="mouse-over-popover-2"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openlog2}
        anchorEl={anchorEl2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose2}
        disableRestoreFocus
      >
        <h6 style={{marginTop:'6px'}}>Click here to update your profile</h6>
      </Popover>
      </Link>
        </div>
        <div className={classes.sectionMobile}>
        <Link to='/v2/user/logout' style={{textDecoration: 'none',color:"#fff",cursor : 'pointer',
        }}>
          <IconButton
            edge="end"
            
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose} 
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            // color="inherit"
            aria-describedby={id}
          >

            <ExitToAppIcon />
          </IconButton>
          <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openlog}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <h6 style={{marginTop:'6px'}}>Click here to logout</h6>
      </Popover>
          
      </Link>
        <Link to='/v2/user/profile' style={{textDecoration: 'none',color:"#fff",cursor : 'pointer',
        }}>
          <IconButton
            edge="end"

          onMouseEnter={handlePopoverOpen2}
          onMouseLeave={handlePopoverClose2} 
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            // color="inherit"
            aria-describedby={id}
          >
            <AccountCircle />

          </IconButton>

          <Popover
        id="mouse-over-popover-2"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openlog2}
        anchorEl={anchorEl2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose2}
        disableRestoreFocus
      >
        <h6 style={{marginTop:'6px'}}>Click here to update your profile</h6>
      </Popover>
      </Link>
          
        </div>
      </Toolbar>
    </AppBar>
    {/* {renderMobileMenu} */}
    {/*{renderMenu} */}
    </div>

    );
}