import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import onWindowReload from "../../utils/reloadFunction";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    width: "425px",
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  let reload = true;
  const {
    workFlowExamMsg,
    setWorkFlowExamMsg,
    showFinishModal,
    onSubmit,
    result,
    showResult,
    showscore,
    queryResult,
    setResumeFlag,
    submissionFailure,
    // examId, examDetail, setWindowSwitch, setTestData, windowSwitchCount, testData
  } = props;

  // onWindowReload(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData)

  const handleClose = async () => {
    await setResumeFlag(false);
    // window.onbeforeunload = (event) =>{
    //   event.preventDefault();
    // }
    window.onbeforeunload = function () {
      return null; // return null to avoid pop up
    };
    // onSubmit('010');
    // reload = false
    window.location.reload("/dashboard");
  };

  // window.onbeforeunload = function (e) {
  //   e.preventDefault();
  // }

  window.onbeforeunload = function () {
    return null; // return null to avoid pop up
  };

  // useEffect(() => {
  //   if (reload) {
  //     const unloadCallback = (event) => {
  //       event.preventDefault();
  //       event.returnValue = "";
  //       return "";
  //     };
  //     window.addEventListener("beforeunload", unloadCallback);
  //     return () => window.removeEventListener("beforeunload", unloadCallback);
  //   }
  // }, []);
  return (
    <div>
      <Modal
        disableEnforceFocus={true}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showFinishModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showFinishModal}>
          {/* <div className={classes.paper}> */}
          <div className={classes.paper} style={{ textAlign: "justify" }}>
            <div style={{ textAlign: "center" }}>
              <h3 id="transition-modal-title" style={{ color: "#6f2981", marginBottom: "30px" }}>
                {showscore === false && queryResult == false && workFlowExamMsg === "" ? "System Status" : "System Warning"}
              </h3>
            </div>
            {/* <p
              id="transition-modal-description"
              style={{ textAlign: "justify" }}
            >
              Your Test has been Submitted. Please attempt Pending Tests.
              <br />
              <br />
              Thanking you,
              <br />
              Support, AssessHub.
            </p> */}
            {showscore ? (
              result !== undefined && result !== null && showResult ? (
                <div className="form-group" style={{ textAlign: "center" }}>
                  <p style={{ fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: result }}></p>
                  <ul className="list-inline">
                    <li>
                      <a className="btn btn-blue" style={{ borderRadius: "10px" }} onClick={() => handleClose()}>
                        <strong>Close</strong>
                      </a>
                    </li>
                  </ul>
                </div>
              ) : (
                <>
                  <p>
                    Your result is being processed. Please{" "}
                    <span style={{ color: "red" }}>
                      <strong>DO NOT </strong>
                    </span>
                    refresh this page or close the browser, else your test result will be considered{" "}
                    <span style={{ color: "red" }}>
                      <strong>INVALID.</strong>
                    </span>
                  </p>
                </>
              )
            ) : (
              <div className="form-group" style={{ textAlign: "center" }}>
                {queryResult ? (
                  <>
                    <p>
                      Your result is being processed. Please{" "}
                      <span style={{ color: "red" }}>
                        <strong>DO NOT </strong>
                      </span>
                      refresh this page or close the browser, else your test result will be considered{" "}
                      <span style={{ color: "red" }}>
                        <strong>INVALID.</strong>
                      </span>
                    </p>
                  </>
                ) : (
                  <>
                    {submissionFailure ? (
                      <>
                        <p id="transition-modal-description" style={{ textAlign: "justify" }}>
                          {workFlowExamMsg !== "" ? (
                            workFlowExamMsg
                          ) : (
                            <>
                              Your test has
                              <span style={{ color: "red" }}>
                                <strong> NOT </strong>
                              </span>
                              been submitted. Please check your internet connection.
                              <br />
                              <br />
                              Your test will be automatically submitted once your connection is restored and you log back in.
                              <br />
                              <br />
                              Thanking you,
                              <br />
                              Support, AssessHub.
                            </>
                          )}
                        </p>
                        <ul className="list-inline">
                          <li>
                            <a className="btn btn-blue" style={{ borderRadius: "10px" }} onClick={handleClose}>
                              <strong>Close</strong>
                            </a>
                          </li>
                        </ul>
                      </>
                    ) : (
                      <>
                        <p id="transition-modal-description" style={{ textAlign: "justify" }}>
                          {workFlowExamMsg !== "" ? workFlowExamMsg : <>Your test has been submitted. Please attempt pending tests if any.</>}
                          <br />
                          <br />
                          Thanking you,
                          <br />
                          Support, AssessHub.
                        </p>
                        <ul className="list-inline">
                          <li>
                            <a className="btn btn-blue" style={{ borderRadius: "10px" }} onClick={handleClose}>
                              <strong>Close</strong>
                            </a>
                          </li>
                        </ul>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
