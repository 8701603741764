import React from "react";
import { makeStyles } from '@mui/styles';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import converter from 'convert-seconds';
const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius : "15px",
    width:"380px",
    
  }
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const {setFinishModal,setFinishModalDetail,showFinishModalDetail,examDetail,testData,onSubmit} = props;
  
  const handleClose = () => {
    setFinishModalDetail(false)
  };
  const handleClick = () => {
    alert("Hi");
    onSubmit('010');
    setFinishModal(true)
  };
 

  let timeLeft = {};
  if (examDetail[0].time_remaining > 0) {
    timeLeft = {
      
      hours: converter(examDetail[0].time_remaining).hours,
      minutes: converter(examDetail[0].time_remaining).minutes,
      seconds: converter(examDetail[0].time_remaining).seconds
    };
  }
  

  return (
    <div>
      
      <Modal
        disableEnforceFocus = {true}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showFinishModalDetail}
        onClose={handleClose}
        onClick={handleClick}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={showFinishModalDetail}>
          {/* <div className={classes.paper}> */}
          <div className={classes.paper} style={{textAlign:"justify"}}>
            <div style={{textAlign:"center"}}>

            <h3 id="transition-modal-title" style={{color:'#6f2981',marginBottom:"30px"}}>System Warning</h3>
            </div>
            <div className="row">
            <div className="finish_modal_left" >
            <h5 style={{fontsize:"16px",height:"25px"}} >No. of questions answered</h5>
            <h5 style={{fontsize:"16px",height:"25px"}} >No. of questions unanswered</h5>
            <h5 style={{fontsize:"16px",height:"25px"}} >Time remaining</h5>        
            </div>
            <div className="finish_modal_right">
                <h5 style={{fontsize:"16px",height:"25px"}} >: <b>{testData.filter((v,i)=>testData[i].buttonStyle === 'Answered').length }</b></h5>
                <h5 style={{fontsize:"16px",height:"25px"}} >: <b>{testData.length - testData.filter((v,i)=>testData[i].buttonStyle === 'Answered').length}</b></h5>
                <h5 style={{fontsize:"16px",height:"25px"}} >: <b>
          {
            `${timeLeft['hours'] == 0 ? '00' : timeLeft['hours'].toString().length == 2 ? timeLeft['hours'] : '0'+timeLeft['hours']} : ${timeLeft['minutes'] == 0 ? '00' : timeLeft['minutes'].toString().length == 2 ? timeLeft['minutes'] : '0'+timeLeft['minutes']} : ${timeLeft['seconds'] == 0 ? '00' : timeLeft['seconds'].toString().length == 2 ? timeLeft['seconds'] : '0'+timeLeft['seconds']} `
          }
                </b></h5>
            
            </div>
            </div>
            
            <h5 id="transition-modal-description">
            Are you sure you want to finish the test ?
            </h5>
            <div className="form-group" style={{textAlign:"center"}}>
					      	<ul className="list-inline btn-list">
                <li><a className="btn btn-blue"  style={{borderRadius:"10px"}} onClick={() => handleClick()}>
                <strong>Yes</strong>
            </a>
                </li>
                <li><a className="btn btn-blue"  style={{borderRadius:"10px"}}  >
                <strong>No</strong>
            </a>
                </li>
              
                </ul>
                </div>
            
            
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
