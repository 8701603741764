import React from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import { makeStyles, useTheme } from "@mui/styles";
import axios from "../utils/axiosConfig";
import Cookies from 'js-cookie';
import classNames from "classnames";
import { useSelector, useDispatch } from 'react-redux'
import Header from '../components/Profile/Header/ProfileAppBar';
import HeaderLink from '../components/Profile/HeaderLink/HeaderLinks';
import Parallax from "../components/Profile/Parallax/Parallax";
import GridContainer from "../components/Profile/GridContainer/GridContainer";
import Button from '../components/Button';
import GridItem from "../components/Profile/GridItem/GridItem";
import NavPills from "../components/Profile/NavPills/NavPills";
import {Face, VpnKey} from "@mui/icons-material";
import VerticalTabs from '../components/Profile/VerticalTab/TabPanel';

import styles from "../components/Profile/profilePage";
import { getOrgId, setOrgId } from '../reducer/dashboardSlice';

// const useStyles = makeStyles(theme => ({
//     root: {
//       display: "flex"
//     }
//   }));
const useStyles = makeStyles(styles);

  export default (props) => {
    const classes = useStyles();
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    const { ...rest } = props;
    let token = Cookies.get('token');

    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [contactNo, setContactNo] = React.useState('');
    const [userInfoId, setUserInfoId] = React.useState(0);
    const [email, setEmail] = React.useState('');

    const dispatch = useDispatch();

    const orgId = useSelector(getOrgId)

    React.useEffect(() => {
      if (orgId != undefined && orgId != "") {
        try {
              axios.get('/dashboard/getProfile',{
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  orgId: orgId,
                }
              }).then(profData =>{
                
                let userData = profData.data.profileData;
                setFirstName(userData.firstname);
                setLastName(userData.lastname);
                setContactNo(userData.contact_number);
                setUserInfoId(userData.id);
                setEmail(userData.email_address);
                dispatch(setOrgId(userData.organization_id))
            }).catch(error => {
                
            })
        } catch (error) {
            
            
        }
      }
    },[])

    return (
        <div>
          <Header
          color="transparent"
          brand="Assesshub"
          rightLinks={<HeaderLink />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax small filter />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
              <GridContainer id='main-body-two' justify="center">
                <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={require("../assets/user.JPG")} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                  <h1 className={classes.title}>{`${firstName} ${lastName}`}</h1>
                  </div>
                </div>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                  <VerticalTabs firstName={firstName} lastName={lastName} contactNo={contactNo}
                  userInfoId={userInfoId} email={email} setFirstName={setFirstName} setLastName={setLastName}
                  setContactNo={setContactNo} setEmail={setEmail} token={token} />
                </GridItem>
              </GridContainer>
            
            </div>
          </div>
        </div>
      </div>
    );

}