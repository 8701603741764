import React from 'react';
import { makeStyles, useTheme } from "@mui/styles";
// import AppBar from '@mui/material/AppBar';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import CloseIcon from '@mui/icons-material/Cancel';
// import Toolbar from '@mui/material/Toolbar';
// import Popover from './PopOverPopUpState';
// import IconButton from "@mui/material/IconButton";
// import Brightness from '@mui/icons-material/Brightness7';
// import Dark from '@mui/icons-material/Brightness4';
import Cookies from 'js-cookie';
import QuestionNoList from '../../components/QuestionNoList';
import Timer from '../Timer';
import Collapsible from 'react-collapsible';
import IconButton from "@mui/material/IconButton";
import Menu from '@mui/icons-material/Menu';
import ahGreenDotLogo from "../../images/assesshub_logo_green_dot.svg";
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  title: {
    flexGrow: 1,
  }
}));

export default (props) => {

  // const classes = useStyles();
  // const theme = useTheme();

  const { showModel, ImageAlertShowModal, AudioAlertShowModal, NoFaceAlertModal, NoMatchAlertModal, MultipleFaceAlertModal, value, showFinishModalDetail, examDetail, setTimeoutModelShow, setTimeoutModelOneMinuteShow, questionNo, testData, setQuestionNo, setQuestionChange, navFlag, isVideoUploading, isTimeUp, setIsTimeUp, isVideoInterviewTest, isRecordingDone, setIsRecordingDone } = props;
  let userName = Cookies.get('userName');

  // const instructionClick = () => {
  //   props.setViewInstruction(true);
  //   // props.value = value;
  // }
  const instructionClick = () => {
    props.setViewInstruction(true);
  }

  return (
    <>
      <div style={{ borderBottom: "1px " }} className="row qa-header navbar-expand-xl button-text app-border">
        <div className="col-xs-12 visible-xs ">
          <div className="logo-sec">
            <a href="#"><img src={ahGreenDotLogo} style={{ height: "35px" }} className="img-responsive center-block" /></a>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div id="c1" >
            <ul className="list-inline new-li header-links header-links-two qnum-links ">
              <li className="nav-line-right"><h5 ><strong>{userName}</strong></h5></li>
              <li className="nav-line-right"><h5 ><strong>{props.examDetail[0].instancename}</strong></h5></li>
              <li className="nav-line-right"><h5 style={{ cursor: "pointer" }} onClick={() => instructionClick()} ><strong>Test Instructions</strong></h5></li>
              <li style={{ paddingRight: "0px" }}><h5><strong>Question {questionNo} / {testData.length}</strong></h5></li>
              <QuestionNoList themeType={props.themeType} testData={testData}
                setQuestionNo={setQuestionNo} questionNo={questionNo}
                setQuestionChange={setQuestionChange} navFlag={navFlag} />
            </ul>
          </div>
          <div id="c2" >
            <h4 style={{ color: "#B45E9B" }}><strong>Time : <Timer showModel={showModel} ImageAlertShowModal={ImageAlertShowModal} AudioAlertShowModal={AudioAlertShowModal} NoFaceAlertModal={NoFaceAlertModal} NoMatchAlertModal={NoMatchAlertModal} MultipleFaceAlertModal={MultipleFaceAlertModal} showFinishModalDetail={showFinishModalDetail} examDetail={examDetail} setTimeoutModelShow={setTimeoutModelShow} setTimeoutModelOneMinuteShow={setTimeoutModelOneMinuteShow} isVideoUploading={isVideoUploading} isTimeUp={isTimeUp} setIsTimeUp={setIsTimeUp} isVideoInterviewTest={isVideoInterviewTest} isRecordingDone={isRecordingDone} setIsRecordingDone={setIsRecordingDone} />&nbsp;</strong></h4>
          </div>
          <div id="c3" >
            <div className="logo-sec" >
              <a href="#"><img src={ahGreenDotLogo} style={{ height: "35px" }} className="img-responsive" /></a>
            </div>
          </div>
        </div>

      </div>




      <div className="qa-header navbar-expand-xl btn-inst app-border">
        <Collapsible trigger={
            <div className="row">
              <div className="col-xs-12">
                <div className="logo-sec" >
                  <ul className="list-inline header-links qnum-links ">
                    <li>
                      <IconButton
                        edge="start"
                        style={{ display: "inline" }}
                      >
                        <Menu />
                      </IconButton>
                      <a style={{ display: "inline" }} href="#"><img style={{ display: "inline-block", width: "auto", height: "35px" }} src={ahGreenDotLogo} className="img-responsive center-block" /></a>
                    </li>
                    <li style={{ float: "right", marginTop: "7px" }}>
                      <h5 style={{color:"#B45E9B"}} className="text-timer "><strong>Time : <Timer  showModel={showModel} ImageAlertShowModal={ImageAlertShowModal} AudioAlertShowModal={AudioAlertShowModal} NoFaceAlertModal={NoFaceAlertModal} NoMatchAlertModal={NoMatchAlertModal} MultipleFaceAlertModal={MultipleFaceAlertModal} examDetail={examDetail} setTimeoutModelShow={setTimeoutModelShow} setTimeoutModelOneMinuteShow={setTimeoutModelOneMinuteShow} isVideoUploading={isVideoUploading} isTimeUp={isTimeUp} setIsTimeUp={setIsTimeUp} isVideoInterviewTest={isVideoInterviewTest} isRecordingDone={isRecordingDone} setIsRecordingDone={setIsRecordingDone}/></strong></h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          }
        >
          <div className="row">
            <div className="col-xs-12">
              <ul className="list-inline header-links qnum-links ">
                {/* <li style={{top:'7px',paddingRight:'11px'}}><div><CloseIcon onClick={() => props.onSubmit('001')} style={{cursor: 'pointer'}} /></div></li> */}
                <li><h5 className="text-usname"><strong>{userName}&nbsp;&nbsp;</strong></h5></li>
                <li><h5 className="text-usname"><strong>{props.examDetail[0].instancename}</strong></h5></li>
                {/* <li><h5 style={{color:"#B45E9B"}} className="text-timer "><strong style={{color:""}}>Time : <Timer examDetail={examDetail} setTimeoutModelShow={setTimeoutModelShow} /></strong></h5> </li> */}
              </ul>
            </div>
            {/* <div className="col-xs-12">
            <ul className="list-inline header-links qnum-links ">
              <li><h5 style={{color:"#B45E9B"}} className="text-timer "><strong>Time : <Timer  showModel={showModel} ImageAlertShowModal={ImageAlertShowModal} AudioAlertShowModal={AudioAlertShowModal} NoFaceAlertModal={NoFaceAlertModal} NoMatchAlertModal={NoMatchAlertModal} MultipleFaceAlertModal={MultipleFaceAlertModal} examDetail={examDetail} setTimeoutModelShow={setTimeoutModelShow} setTimeoutModelOneMinuteShow={setTimeoutModelOneMinuteShow} /></strong></h5> </li>
            </ul>
          </div> */}
            <div className="col-xs-12">
              <ul className="list-inline header-links qnum-links">
                <li><h5 className="text-usname"><strong>Question {questionNo} / {testData.length}</strong></h5></li>
                <QuestionNoList style={{ textAlign: "center" }} themeType={props.themeType} testData={testData}
                  setQuestionNo={setQuestionNo} questionNo={questionNo}
                  setQuestionChange={setQuestionChange} navFlag={navFlag} />
                <li><h5 style={{ cursor: "pointer", textDecoration: "none", margin: "4px" }} color="inherit" onClick={() => instructionClick()} ><strong>Test Instructions</strong></h5></li>
              </ul>
            </div>
          </div>
        </Collapsible>
      </div>
    </>
  );
}