import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import SignIn from './page/Signin';
import Dashboard from './page/Dashboard';
import Profile from './page/Profile';
import Test from './page/Test';
import SignOut from './page/Signout';
import Cookies from 'js-cookie';

const UserRoutes = (props) => {
  const [userExist, setUser] = React.useState(false);
console.log(props);
  const isAuthenticated = Cookies.get("sessionTime") && Cookies.get("sessionTime") === '0';

  return (
    <Routes>
      {/* Dashboard Route */}
      <Route
        path='/v2/user/dashboard'
        element={isAuthenticated ? (
          <Dashboard themeType={props.themeType} changeTheme={props.changeTheme} />
        ) : (
          <Navigate to="/v2/user/login" />
        )}
      />

      {/* Profile Route */}
      <Route
        path='/v2/user/profile'
        element={isAuthenticated ? (
          <Profile themeType={props.themeType} changeTheme={props.changeTheme} />
        ) : (
          <Navigate to="/v2/user/dashboard" />
        )}
      />

      {/* Logout Route */}
      <Route
        path='/v2/user/logout'
        element={
          <SignOut setUser={setUser} userExist={userExist} />
        }
      />

      {/* Test Route */}
      <Route
        path='/v2/user/test'
        element={<Test themeType={props.themeType} changeTheme={props.changeTheme} />}
      />

      {/* Root Route */}
      <Route
        path='/v2/user/'
        element={isAuthenticated ? (
          <Navigate to="/v2/user/dashboard" />
        ) : (
          <SignIn setUser={setUser} userExist={userExist} />
        )}
      />

      {/* Login Route */}
      <Route
        path='/v2/user/login'
        element={isAuthenticated ? (
          <Navigate to="/v2/user/dashboard" />
        ) : (
          <SignIn
            setUser={setUser}
            userExist={userExist}
            forceLogout={Cookies.get("sessionTime") === '2' && Cookies.get('token') === "" ? <>Session expired. Please login again!</> : ''}
          />
        )}
      />

      {/* Default Route */}
      <Route path="*" element={<Navigate to="/v2/user/dashboard" />} />
    </Routes>
  );
};

export default UserRoutes;
