import React from "react";
import { withStyles, makeStyles } from '@mui/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import Cookies from "js-cookie";
import cache from "memory-cache";
import axios from "./../utils/axiosConfig";
import Modal from "./Test/WindowSwitch/SwitchModal";
import { Grid } from "@mui/material";
import { LoaderProcessingOutlined } from "./LoaderProcessing";
import useUnload from "./UseUnload";
import modalHandler from "../utils/modalHandler";
import LanguageModal from "./Test/LanguageModal";
import { useSelector } from "react-redux";
import { getUserHomeDetails } from "../reducer/dashboardSlice";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

// const formattedDate = date.toLocaleDateString('en-GB', {
//   day: 'numeric', month: 'short', year: 'numeric'
// }).replace(/ /g, '-');

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 340,
  },
  root: {
    background: "#fff",
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();
  const { tableData, status, typeButton, setModal, setTestData, examId, setExamId, buttonName, setCodeLanguages } = props;
  let token = Cookies.get("token");
  const [msgModal, setMsgModal] = React.useState(false);
  const [showModel, setModelShow] = React.useState(false);
  const [ind, setInd] = React.useState(0);
  const [loading, setLoading] = React.useState({ index: "", status: false, name: "" });
  const [resumeLoader, setResumeLoader] = React.useState({ index: "", status: false, name: "" });
  const [messageModal, setMessageModal] = React.useState("");
  const [message, setMessage] = React.useState("");
  // const [showtb, setShowtb] = React.useState(tableData === undefined ? false : true);
  const [showLanguageModal, setShowLanguageModal] = React.useState(false);
  const [modalHandlerData, setModalHandlerData] = React.useState({});
  const userHomeData = useSelector(getUserHomeDetails);

  if (tableData["tableHeaders"] !== undefined) {
    //  tableData['001']!==undefined?setInd(tableData['001'].length):setInd(0) ;
  }

  React.useEffect(() => {
    tableData["001"] !== undefined ? setInd(tableData["001"].length) : setInd(0);
    // setInd(tableData['001'].length );
  });

  window.onunload = function (event) {
    // onload event handler
    event.preventDefault();
    window.localStorage.removeItem(examId);
  };

  return (
    <Grid item xs={12}>
      <Paper className={classes.root}>
        {/* #8080801f */}
        <div className="listing_div">
          <div className="list_head">
            <p>
              {tableData && tableData["tableHeaders"] !== undefined ? (
                tableData["tableHeaders"].split(",").map((item, index) => {
                  return (
                    <span
                      style={{ lineHeight: "2" }}
                      className={
                        index === 0
                          ? "srno"
                          : index === 1
                          ? "name"
                          : index === 2
                          ? "status"
                          : index === 3
                          ? "time"
                          : index === 4
                          ? "list_action-t"
                          : ""
                      }
                      key={index}
                    >
                      {item}
                    </span>
                  );
                })
              ) : (
                <></>
              )}
            </p>
          </div>
          {tableData["001"] !== undefined ? (
            tableData["001"].map((row, index) => {
              return (
                <div key={index} className={index % 2 != 0 ? "list_even list_name2" : "list_odd list_name2"}>
                  <div key={index} className="list_detail">
                    <span className="srno">{index + 1}</span>

                    <span className="name">{row.examName}</span>
                    <span className="status">
                      <strong className="viewInMob">Status - </strong>IN PROGRESS
                    </span>
                    <span className="time">
                      {new Date(row.date).toLocaleDateString("en-GB", { day: "numeric", month: "short", year: "numeric" }).replace(/ /g, "-") + "   "}
                      {new Date(row.date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
                    </span>
                  </div>
                  <div className="list_action-t">
                    {resumeLoader.status && resumeLoader.index === index && resumeLoader.name === `resume_${buttonName}_${index}` ? (
                      <LoaderProcessingOutlined />
                    ) : (
                      <Button
                        variant="outlined"
                        name={`resume_${buttonName}_${index}`}
                        style={{ top: "-5px" }}
                        color="primary"
                        onClick={(e) =>
                          modalHandler(
                            row,
                            index,
                            e,
                            props,
                            setLoading,
                            setModelShow,
                            setMsgModal,
                            setResumeLoader,
                            setMessageModal,
                            setMessage,
                            setCodeLanguages
                          )
                        }
                      >
                        Resume
                      </Button>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
          {tableData["000"] !== undefined ? (
            tableData["000"].map((row, index) => {
              return (
                <div key={index} className={(ind + index) % 2 != 0 ? "list_even list_name2" : "list_odd list_name2"}>
                  <div className="list_detail">
                    <span className="srno">{ind + index + 1}</span>

                    <span className="name">{row.examName}</span>
                    <span className="status">
                      <strong className="viewInMob">Status - </strong>NOT STARTED
                    </span>
                    <span className="time">
                      {new Date(row.date)
                        .toLocaleDateString("en-GB", { day: "numeric", month: "short", year: "numeric", time: "numeric" })
                        .replace(/ /g, "-") + "   "}{" "}
                      {new Date(row.date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}
                    </span>
                  </div>
                  <div className="list_action-t">
                    {loading.status && loading.index === index && loading.name === `TakeTest_${buttonName}_${index}` ? (
                      <LoaderProcessingOutlined />
                    ) : (
                      <Button
                        name={`TakeTest_${buttonName}_${index}`}
                        disabled={new Date(row.date) > new Date()}
                        variant="outlined"
                        style={{ top: "-5px" }}
                        color="primary"
                        onClick={
                          row.languageLocale != null && row.languageLocale.split(",").length > 1
                            ? (e) => {
                                setModalHandlerData({
                                  row,
                                  index,
                                  name: e.currentTarget.name,
                                  userExamId: row.userExamId,
                                  props,
                                  setLoading,
                                  setModelShow,
                                  setMsgModal,
                                  setResumeLoader,
                                  setMessageModal,
                                  setMessage,
                                  setCodeLanguages,
                                });
                                setShowLanguageModal(true);
                              }
                            : (e) => {
                                modalHandler(
                                  row,
                                  index,
                                  e.currentTarget.name,
                                  props,
                                  setLoading,
                                  setModelShow,
                                  setMsgModal,
                                  setResumeLoader,
                                  setMessageModal,
                                  setMessage,
                                  setCodeLanguages,
                                  row.languageLocale
                                );
                              }
                        }
                      >
                        {`Take ${typeButton === "Survey" ? "Survey" : "Test"} Online`}
                      </Button>
                    )}
                  </div>

                  {showLanguageModal ? (
                    <LanguageModal
                      showLanguageModal={showLanguageModal}
                      setShowLanguageModal={setShowLanguageModal}
                      modalHandlerData={modalHandlerData}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              );
            })
          ) : (
            <></>
          )}
          {tableData && tableData[Object.keys(tableData)[0]] === undefined && (
            <>
              <div className="list_odd list_name" style={{ padding: "0px", lineHeight: "3" }}>
                <div className="list_detail" style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                    {typeButton === "Feedback"
                      ? "No Pending Feedback"
                      : typeButton === "Survey"
                      ? "No Pending Survey"
                      : typeButton === "WorkFlow"
                      ? (userHomeData && userHomeData.isWorkflowInProgress == true ? "Your result is being processed... Please refresh your screen within the next 2 minutes to check if any new assessments have been assigned to you." : "No Pending WorkFlow")
                      : "No Pending Tests"}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
        {/* <Table className={classes.table} aria-label="customized table">
        <TableHead className="thead-dark">
          <TableRow>
            {
              tableData['tableHeaders'] !== undefined ?
              tableData['tableHeaders'].split(',').map((item,index) => {
                return (<StyledTableCell key={index}>{item}</StyledTableCell>)
              })
              :
              <></>
            }


          </TableRow>
        </TableHead>
        <TableBody>
          {
            tableData['001'] !== undefined ?
              tableData['001'].map((row, index) => {
                return(
                  <StyledTableRow key={`${'001'}_${index}`}>
                    <StyledTableCell  component="th" scope="row">
                      {index+1}
                    </StyledTableCell>
                    <StyledTableCell >{row.examName}</StyledTableCell>
                    <StyledTableCell >IN PROGRESS</StyledTableCell>
                    <StyledTableCell >{row.date}</StyledTableCell>
                    <StyledTableCell>
                      <Button variant="outlined" color="primary" onClick={() => modalHandler(row)}>
                      Resume
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            :
            <></>
          }
          {
            tableData['000'] !== undefined ?
              tableData['000'].map((row, index) => {
                return(
                  <StyledTableRow key={`${'000'}_${index}`}>
                    <StyledTableCell  component="th" scope="row">
                      {ind+index+1}
                    </StyledTableCell>
                    <StyledTableCell >{row.examName}</StyledTableCell>
                    <StyledTableCell >NOT STARTED</StyledTableCell>
                    <StyledTableCell >{row.date}</StyledTableCell>
                    <StyledTableCell>
                      <Button variant="outlined" color="primary" onClick={() => modalHandler(row)}>
                      {`Take ${typeButton === 'Survey' ? 'Survey' : 'Test'} Online`}
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })
            :
            <></>
          }

        </TableBody>
      </Table> */}
        {msgModal ? <Modal headerMessage={messageModal} message={message ? message : ""} showModel={showModel} /> : <> </>}
      </Paper>
    </Grid>
  );
}
