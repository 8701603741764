import React from "react";
import { makeStyles } from '@mui/styles';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";


const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius : "15px",
    width:"380px",
    
  }
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const {open,setOpen} = props;
  const handleClose = () => {
      setOpen(false)
      
 
  };

  return (
    <div>
      
      <Modal
      
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        PaperProps={{
          style: {
            backgroundColor:"#e5e5e9"
          }
        }}
      >
        <Fade in={open}>
          {/* <div className={classes.paper}> */}
          <div className={classes.paper} style={{textAlign:"justify"}}>
          <div style={{textAlign:"center"}}>

<h3 id="transition-modal-title" style={{color:'#6f2981',marginBottom:"30px"}}>System Warning</h3>
</div>
            
            <p id="transition-modal-description">
            Your request is being processed. It'll take a while. 
            <br/>
          <strong>Please, check out after some time.</strong>
            <br/>
           
            </p>
            <div className="form-group" style={{textAlign:"center"}}>
					      	<ul className="list-inline btn-list">
                <li><button className="btn btn-blue"  style={{borderRadius:"10px"}} onClick={() => handleClose()} >
                <strong>Close</strong>
            </button>
                </li>
              
                </ul>
                </div>
            
            
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
