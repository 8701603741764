import React, { useState } from 'react';
import { makeStyles, useTheme } from "@mui/styles";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import VideoContainer from '../Webcam/webcam';
import SpeechToText from '../TextBox/textbox';
import Big5Radial from '../Big5Analysis/big5radial';
import Big5Data from '../Big5TextData/Big5Data';
// import Sentiment from '../Sentiment/sentimentanalysis';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    //textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function FullWidthGrid(props) {
  const classes = useStyles();
    // const theme = useTheme();
    const [big5, setBig5Value] = useState([0,0,0,0,0]);
    const [data, setData] = React.useState([]);
  const {setFacialArray, setBig5, setSentiment} = props;

    React.useEffect( ()=>{

      let facialArray = [];
      let angryF = 0, disgustedF = 0, fearfulF = 0, happyF = 0, neutralF = 0, sadF = 0, surprisedF = 0;
        for(let i = 0; i < data.length; i++){
          let elem = data[i];
          let {angry, disgusted, fearful, happy, neutral, sad, surprised} = elem.expressions;
            angryF +=  parseFloat(angry.toString().includes('e') ? parseFloat(0) : parseFloat(angry).toFixed(2));
            disgustedF +=  parseFloat(disgusted.toString().includes('e') ? parseFloat(0) : parseFloat(disgusted).toFixed(2));
            fearfulF +=  parseFloat(fearful.toString().includes('e') ? parseFloat(0) : parseFloat(fearful).toFixed(2));
            happyF +=  parseFloat(happy.toString().includes('e') ? parseFloat(0) : parseFloat(happy).toFixed(2));
            neutralF +=  parseFloat(neutral.toString().includes('e') ? parseFloat(0) : parseFloat(neutral).toFixed(2));
            sadF +=  parseFloat(sad.toString().includes('e') ? parseFloat(0) : parseFloat(sad).toFixed(2));
            surprisedF +=  parseFloat(surprised.toString().includes('e') ? parseFloat(0) : parseFloat(surprised).toFixed(2));
        }
        facialArray =  [parseInt((angryF / data.length) * 100) ,
          parseInt((disgustedF / data.length) * 100),
          parseInt((fearfulF / data.length) * 100), 
          parseInt((happyF / data.length) * 100), 
          parseInt((neutralF / data.length) * 100), 
          parseInt((sadF / data.length) * 100), 
          parseInt((surprisedF / data.length) * 100)];

          setFacialArray(facialArray);
    },[data])
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        
        <Grid item xs={12}>
          <Paper className={classes.paper} style={{textAlign: 'center'}}>
              <VideoContainer setFacialArray={setFacialArray} setBig5={setBig5} setSentiment={setSentiment} data={data} setData={setData} />
          </Paper>
        </Grid>
        <div style={{display:'none'}}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
              <SpeechToText setBig5Value={setBig5Value} themeType={props.themeType} setFacialArray={setFacialArray} setBig5={setBig5} setSentiment={setSentiment}/>
          </Paper>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
              <Sentiment />
          </Paper>
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} style={{ height: '503px'}}>

              <Big5Radial  big5={big5} classes={classes} themeType={props.themeType} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
                <Big5Data big5={big5} themeType={props.themeType}/>
          </Paper>
        </Grid>
        </div>
      </Grid>
    </div>
  );
}
