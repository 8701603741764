import React from "react";
import { makeStyles } from '@mui/styles';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import onWindowReload from "../../utils/reloadFunction";
const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius : "15px",
    width:"380px",

  }
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const {showTimeOutModel, onSubmit, setFinishModal, setTimeoutModelShow,result, showResult, showscore,updateFlag,
    // examId, examDetail, setWindowSwitch, setTestData, windowSwitchCount, testData
  } = props;

  // onWindowReload(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData)

  const handleClose = () => {
    // onSubmit('011');
    // window.onbeforeunload = (event) =>{
    //   event.preventDefault();
    // }

    window.onbeforeunload = function () {
      return null;  // return null to avoid pop up
    }

    window.location.reload('/dashboard')
    // setTimeoutModelShow(false)
    // setFinishModal(true)
  };

  return (
    <div>

      <Modal
        disableEnforceFocus = {true}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showTimeOutModel}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={showTimeOutModel}>
        <div className={classes.paper} style={{textAlign:"justify"}}>

          <div style={{textAlign:"center"}}>

<h3 id="transition-modal-title" style={{color:'#6f2981',marginBottom:"30px"}}>System Warning</h3>
</div>
            <p id="transition-modal-description">
            Your Test has been auto-submitted.
            <br/>
            <br/>
            Thanking you,
            <br/>
            Support, AssessHub.
            </p>
            {(showscore || !updateFlag) ? (result !== undefined && result !== null && showResult
            ? <div className="form-group" style={{textAlign:"center"}}>
                <p style={{fontWeight:'bold'}} dangerouslySetInnerHTML={{__html: result}}></p>
					      <ul className="list-inline">
                <li><a className="btn btn-blue"  style={{borderRadius:"10px"}} onClick={() => handleClose()} ><strong>Close</strong></a></li>
                </ul>
                </div> :
                 <p>
                 Your result is being processed. Please <span style={{ color: 'red' }}><strong>DO NOT </strong></span>refresh this page or close the browser, else your test result will be considered <span style={{ color: 'red' }}><strong>INVALID.</strong></span>
                </p>):
                  <div className="form-group" style={{textAlign:"center"}}>
					      <ul className="list-inline">
                <li><a className="btn btn-blue"  style={{borderRadius:"10px"}} onClick={() => handleClose()} ><strong>Close</strong></a></li>
                </ul>
                </div>}
            {/* <div className="form-group" style={{textAlign:"center"}}>
					      	<ul className="list-inline btn-list">
                <li><a className="btn btn-blue" style={{borderRadius:"10px"}} onClick={() => handleClose()} >
                <strong>Close</strong>
            </a>
                </li>

                </ul>
                </div> */}

          </div>
        </Fade>
      </Modal>
    </div>
  );
}
