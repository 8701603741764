import React from 'react';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// import FaceIcon from '@mui/icons-material/Face';
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// import IconButton from "@mui/material/IconButton";
// import Badge from '@mui/material/Badge';
// import MailIcon from '@mui/icons-material/Mail';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import MoreIcon from '@mui/icons-material/MoreVert';
// import Brightness from '@mui/icons-material/Brightness7';
// import Dark from '@mui/icons-material/Brightness4';
// import AccountCircle from '@mui/icons-material/AccountCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ProfileList from './List';

const useStyles = makeStyles(theme => ({
  root: {
    right:'0px',
    width: '100%',
    maxWidth: '200px',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(0),
    padding:'2px'
  },
}));

export default function NestedList(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      
      className={classes.root}
    >
      <ListItem style={{padding:'2px'}} button onClick={handleClick}>
      <ListItem button className={classes.nested}>
            <ListItemIcon>
              <ProfileList />
            </ListItemIcon>
          </ListItem>
      </ListItem>
    </List>
  );
}
