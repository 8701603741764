import React from 'react';
import { withStyles, makeStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
const useStyles = makeStyles({
    table: {
        minWidth: 340,
    },
    root: {
    },
    tableCell: {
        paddingRight: 4,
        paddingLeft: 5
    }
    
});
export default function CustomizedTables(props) {
    const classes = useStyles();
    const {adminData,tableData,setActiveUser} = props;

    return (
        <Grid item xs={12}>
            <Paper className={classes.root}>
                <div className="listing_div">
                    <div className="list_head">
                        <p>
                            <span className="admin-srno" >Sr.No</span>
                            <span className="admin-name-ongoing" >Username</span>
                            <span className="admin-userexamid-ongoing" style={{ textAlign: "center" }}>UserExam Id</span>
                            <span className="admin-browser-ongoing" >Browser</span>
                            <span className="admin-device-ongoing" >Device</span>
                            <span className="admin-testid-ongoing" style={{ textAlign: "center" }}>Test Id</span>
                            <span className="admin-testname-ongoing" >Test Name</span>
                            <span className="admin-totalquestion-ongoing" style={{ textAlign: "center" }}>#Questions</span>
                            <span className="admin-answered-ongoing" style={{ textAlign: "center" }}>#Answered</span>
                            <span className="admin-timeremaining-ongoing" >Time Remaining</span>
                            <span className="admin-lastupdated-ongoing" >Last Updated</span>
                        </p>
                    </div>
                    {
                         tableData.length > 0 ?
                            tableData.sort((item1, item2) => new Date(item2[1]['updatedAt']) - new Date(item1[1]['updatedAt'])).map((item,index) => {
                                return (
                                <><div key={index + Date.now()} className={index % 2 !== 0 ? "list_even list_name" : "list_odd list_name"}  >
                                <div key={index + Date.now()} className="list_detail_2">
                                <span className="admin-srno"><strong className="viewInMob">Srno : </strong>{index + 1}</span>
                                <span  className="admin-name-ongoing"><strong className="viewInMob">Username : </strong>{item[1]['username']}</span>
                                <span  className="admin-userexamid-ongoing" style={{ textAlign: "center" }}><strong className="viewInMob">UserExam Id : </strong>{item[1]['userExamId']}</span>
                                <span  className="admin-browser-ongoing"><strong className="viewInMob">Browser : </strong>{item[1]['browser']}</span>
                                <span  className="admin-device-ongoing"><strong className="viewInMob">Device : </strong>{item[1]['device']}</span>
                                <span  className="admin-testid-ongoing" style={{ textAlign: "center" }}><strong className="viewInMob">Test Id : </strong>{item[1]['testId']}</span>
                                <span  className="admin-testname-ongoing"><strong className="viewInMob">Test Name : </strong>{item[1]['testName']}</span>
                                <span  className="admin-totalquestion-ongoing" style={{ textAlign: "center" }}><strong className="viewInMob">#Questions : </strong>{item[1]['totalQuestion']}</span>
                                <span  className="admin-answered-ongoing" style={{ textAlign: "center" }}><strong className="viewInMob">#Answered : </strong>{item[1]['answeredQuestionsCount']}</span>
                                <span  className="admin-timeremaining-ongoing"><strong className="viewInMob">Time Remaining : </strong>{item[1]['timeRemaining'] != "-" && item[1]['timeRemaining'] != undefined ? String(Math.trunc(Number(item[1]['timeRemaining']) / 3600)) + ":" + (Math.trunc(Number(item[1]['timeRemaining']) % 3600 / 60) < 10 ? "0" : "") + String(Math.trunc(Number(item[1]['timeRemaining']) % 3600 / 60)) + ":" + (Math.trunc(Number(item[1]['timeRemaining']) % 3600 % 60) < 10 ? "0" : "") + String(Math.trunc(Number(item[1]['timeRemaining']) % 3600 % 60)) : "-"}</span>
                                <span  className="admin-lastupdated-ongoing"><strong className="viewInMob">Last Updated : </strong>{item[1]['updatedAt'] != "-" && item[1]['updatedAt'] != undefined ? new Date(item[1]['updatedAt']).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-')+"   "+new Date(item[1]['updatedAt']).toLocaleTimeString([],{hour:'2-digit',minute:'2-digit',hour12:true}) : "-"}</span>
                                </div>
                                </div>
                                </>);
                                })
                                :
                                <><div className="list_odd list_name" style={{ padding: '0px', lineHeight: '3' }}  >
                                    <div className="list_detail" style={{ textAlign: 'center' }}>
                                        <span style={{ fontSize: '15px', fontWeight: 'bold' }}>No Users Found</span>
                                    </div>
                                </div></>
                    }
                                  </div>
            </Paper>
        </Grid>
    );
}