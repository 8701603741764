import React from 'react';
import { makeStyles, useTheme } from "@mui/styles";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    title: {
      flexGrow: 1,
    }
}));

export default (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const {value} = props;

    return (

        <Grid style={{padding: '5%', textAlign: 'center'}}>
             
        <Grid item style={{display: 'flex'}} >
            <Paper
                style={{
                paddingLeft: value < 9 ? "17px" : "11px",
                paddingRight: value < 9 ? "22px" : "28px",
                backgroundColor: '#8BC34A'
                }}
            >     
            </Paper>
            <Typography
             style={{padding:'0px'}}>&nbsp;Answered</Typography>
            </Grid>
            <br></br>
               <Grid item style={{display: 'flex'}}>
            <Paper
                style={{
                paddingLeft: value < 9 ? "17px" : "11px",
                paddingRight: value < 9 ? "22px" : "28px",
                backgroundColor: '#ff8a65'
                }}
            >
                
            </Paper>
            <Typography style={{padding:'0px'}}>&nbsp; Not Answered</Typography>
            </Grid><br></br>
              <Grid item style={{display: 'flex'}} >
            <Paper
                style={{
                paddingLeft: value < 9 ? "17px" : "11px",
                paddingRight: value < 9 ? "22px" : "28px",
                backgroundColor: '#FFD487'
                }}
            >
                
            </Paper>
            <Typography style={{padding:'0px'}}>&nbsp; Not Visited</Typography>
            </Grid><br></br>
               <Grid item style={{display: 'flex'}} >
            <Paper
                style={{
                paddingLeft: value < 9 ? "17px" : "11px",
                paddingRight: value < 9 ? "22px" : "28px",
                backgroundColor: '#9575cd'
                }}
            >
                
            </Paper>
            <Typography style={{padding:'0px'}}>&nbsp; Marked Review</Typography>
            </Grid><br></br>
          
        </Grid>
    );
}