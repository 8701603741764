import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import saveToMongoFunction from "../utils/saveToMongoFunction";
import SwitchModal from './Test/WindowSwitch/SwitchModal';

const WindowFocusHandler = (props) => {
  const {showModel,setModelShow,setWindowSwitch, windowSwitchCount, examId, examDetail, testData, setTestData, closeModal, onSubmit, result, showResult, showscore, updateFlag,setWebProcteringZero,webProctoring} = props;
  const [onReload,setOnReload] = React.useState(true)
  // User has switched back to the tab
  const onFocus = () => {
    // console.log('Tab is in focus');
    //  setModelShow(true);
  };
  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    if(onReload === true || onReload === undefined){ 
    if (examDetail[0].webproctoringcount >= windowSwitchCount) {
      //setWindowSwitch(windowSwitchCount + 1);
    }

    if ((examDetail[0].webproctoringcount - windowSwitchCount) < 0) {
      setModelShow(true);
      // setModelShow(false);
    } else {
      setModelShow(true);
    }
   
  }
  else {
    // setWindowSwitch(windowSwitchCount +1);
    setModelShow(false)
    setOnReload()
  }
  };

  const onBeforeUnload = (event) => {
    // setWindowSwitch(windowSwitchCount - 1)
    setOnReload(false)
    setModelShow(false);
    if (event.currentTarget) {
      if (examDetail[0].webproctoringcount - windowSwitchCount >= 0) {
        saveToMongoFunction(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData)
      }
      //setWindowSwitch(windowSwitchCount +1)
      setOnReload(false); //
    }
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    window.addEventListener('beforeunload',(e) => onBeforeUnload(e));
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('beforeunload',(e) => onBeforeUnload(e));
    };
  });
  return (
          <>{
            // (examDetail[0].webproctoringcount - windowSwitchCount) < 0
            // ?
            // onSubmit('111')
            // :
          showModel 
              ?
              //onReload === false || onReload === undefined ?
              examDetail[0].webproctoringcount >= 0 //&& !examDetail[0].navflag
              ? 
                <SwitchModal updateFlag={updateFlag} result={result} showResult={showResult} showscore={showscore} showModel={showModel} setModelShow={setModelShow} setOnReload = {setOnReload}
                message = {(examDetail[0].webproctoringcount - windowSwitchCount) < 0 
                  ? 
                  "<div>We are Sorry to inform you that your test has been terminated.</div>" 
                  :
                  (examDetail[0].webproctoringcount - windowSwitchCount) === 0
                  ?
                  "<div>You cannot switch to another window while giving this test. If you attempt the same <u style={{color:'red'}}><b>ONCE MORE</b></u>, your test will be terminated immediately.<br/><br/>Thanking you, <br/>Support, AssessHub.</div>"
                  :
                  "<div>You cannot switch to another window while giving this test. If you attempt the same, your test will be terminated immediately.<br/><br/>Thanking you, <br/>Support, AssessHub.</div>"
                }
                header = {(examDetail[0].webproctoringcount - windowSwitchCount) < 0 
                  ? 
                  'Violation of Rule !!!'
                  :
                  (examDetail[0].webproctoringcount - windowSwitchCount) === 0
                  ?
                  'LAST WARNING !!!'
                  :
                  'WARNING !!!'
                } closeModal = {closeModal} onSubmit = {onSubmit} setWindowSwitch = {setWindowSwitch}/>
              :
              <></>
            :
              <></>
            // :
            // <></>
            }
          </>
        );
};
export default WindowFocusHandler;