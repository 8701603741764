export let localhostConstants = {
    name: "localhost",
    urlUniqueString: "localhost",
    url: "http://localhost:3006/v3",
    answersBaseURL: "",
    resultBaseURL: "http://localhost:3003/v3",
    proctoringURL: "",
    azureStorageURL: "https://v2testingstorage.blob.core.windows.net/user-videointerview/",
    azureSpeechStorageURL: "https://v2testingstorage.blob.core.windows.net/user-speech-evaluation/",
    adminBackendURL: "http://localhost:4002",
}

export let serverConstants = [
    {
        name: "v1-production",
        urlUniqueString: "user.assesshub.co.in",
        url: "https://api-user.assesshub.co.in/v3",
        answersBaseURL: "https://answers.assesshub.co.in",
        resultBaseURL: "https://result.assesshub.co.in/v3",
        proctoringURL: "https://user.assesshub.co.in/v2",
        azureStorageURL: "https://v2prodstorage.blob.core.windows.net/user-videointerview/",
        azureSpeechStorageURL: "https://v2prodstorage.blob.core.windows.net/user-speech-evaluation/",
        adminBackendURL: "https://admin-api.assesshub.co.in",
    },
    {
        name: "v2-production",
        urlUniqueString: "user.assesshub.com",
        url: "https://api-user.assesshub.com/v3",
        answersBaseURL: "https://answers.assesshub.com",
        resultBaseURL: "https://result.assesshub.com/v3",
        proctoringURL: "https://user.assesshub.com/v2",
        azureStorageURL: "https://v2prodstorage.blob.core.windows.net/user-videointerview/",
        azureSpeechStorageURL: "https://v2prodstorage.blob.core.windows.net/user-speech-evaluation/",
        adminBackendURL: "https://admin-api.assesshub.com",
    },
    {
        name: "v2-jumpbox-production",
        urlUniqueString: "user.assesshubdirect.com",
        url: "https://api-user.assesshubdirect.com/v3",
        answersBaseURL: "https://answers.assesshubdirect.com",
        resultBaseURL: "https://result.assesshubdirect.com/v3",
        proctoringURL: "https://user.assesshubdirect.com/v2",
        azureStorageURL: "https://v2prodstorage.blob.core.windows.net/user-videointerview/",
        azureSpeechStorageURL: "https://v2prodstorage.blob.core.windows.net/user-speech-evaluation/",
        adminBackendURL: "https://admin-api.assesshubdirect.com",
    },
    {
        name: "v2-staging",
        urlUniqueString: "user.myassesshub.com",
        url: "https://api-user.myassesshub.com/v3",
        answersBaseURL: "https://answers.myassesshub.com",
        resultBaseURL: "https://result.myassesshub.com/v3",
        proctoringURL: "https://user.myassesshub.com/v2",
        azureStorageURL: "https://v2testingstorage.blob.core.windows.net/user-videointerview/",
        azureSpeechStorageURL: "https://v2testingstorage.blob.core.windows.net/user-speech-evaluation/",
        adminBackendURL: "https://admin-api.myassesshub.com",
    },
    {
        name: "v2-staging-2",
        urlUniqueString: "user2.assesshub.com",
        url: "https://user2-api.assesshub.com/v3",
        answersBaseURL: "https://answers.assesshub.com",
        resultBaseURL: "https://result.assesshub.com/v3",
        proctoringURL: "https://user2.assesshub.com/v2",
        azureStorageURL: "https://v2prodstorage.blob.core.windows.net/user-videointerview/",
        azureSpeechStorageURL: "https://v2prodstorage.blob.core.windows.net/user-speech-evaluation/",
        adminBackendURL: "https://admin2-api.assesshub.com",
    }
]