import saveToMongoFunction from "./saveToMongoFunction";

const onFinishSaveToMongoFunction = async (examId, examDetail, setWindowSwitch, setTestData, windowSwitchCount, testData, statusOnFinish) => {
  let response = await saveToMongoFunction(examId, examDetail, setWindowSwitch, setTestData, windowSwitchCount, testData, statusOnFinish);

  return response;

  // if (response === true) {
  //   return true;
  // } else {
  // let response = await saveToMongoFunction(examId, examDetail, setWindowSwitch, setTestData, windowSwitchCount, testData, statusOnFinish);
  // if (response === true) {
  //   return true;
  // } else {
  //   return false;
  // }
  // }
};

export default onFinishSaveToMongoFunction;
