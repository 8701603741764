import React, {useEffect} from "react";
import { makeStyles } from '@mui/styles';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    width: "380px"
  }
}));

export default function TransitionsModal({ onConfirm, open, Count }) {

  const classes = useStyles();
  

  return (
    <div>
      <Modal
        open={open}
        disableEnforceFocus={true}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        onClick={onConfirm}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
        <div className={classes.paper} style={{ textAlign: "justify" }}>
        {Count === 1 ?
            <h3 id="transition-modal-title" style={{ color: '#6f2981', marginBottom: "30px", textAlign: 'center' }}>
              Warning!
            </h3> :
            (Count === 2 ?
              <h3 id="transition-modal-title" style={{ color: '#6f2981', marginBottom: "30px", textAlign: 'center'}}>
              Warning!!
            </h3> 
            : (Count === 3 ?
              <h3 id="transition-modal-title" style={{ color: '#6f2981', marginBottom: "30px", textAlign: 'center' }}>
              Warning!!!
            </h3> : (Count === 4 &&
             <h3 id="transition-modal-title" style={{ color: '#6f2981', marginBottom: "30px", textAlign: 'center' }}>
              Violation of Rule !!!
           </h3>)))}
           {Count === 1 ?
            <p id="transition-modal-description" style={{ textAlign: 'center'}}>
            Multiple speakers detected.
          </p> :
            (Count === 2 ?
              <p id="transition-modal-description">
              This is the 2nd warning of multiple speakers.  Please ensure that no one is near to you, else you will not be allowed to attempt this test.
            </p>
            : (Count === 3 ?
              <p id="transition-modal-description">
              This is the final warning where multiple speakers detected. If this happens again, your test results will get submitted automatically.
            </p> 
            : (Count === 4 &&
              <p id="transition-modal-description">
              You have violated the test requirement. You will not be allowed another attempt. Your test results are being processed. 
            </p> )))}
            {(Count === 1 || Count === 2 || Count === 3) &&
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <button className="btn btn-blue" style={{ borderRadius: "10px" }} onClick={onConfirm}>
              Ok
            </button>
          </div>}
          {(Count === 4) &&
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <button className="btn btn-blue" style={{ borderRadius: "10px" }} onClick={onConfirm}>
              Close
            </button>
          </div>}


            
            
            
          </div>
          </Fade>
          {/* <Fade in={open}>
          {Count === 2 && <div className={classes.paper} style={{ textAlign: "justify" }}>
            <h3 id="transition-modal-title" style={{ color: '#6f2981', marginBottom: "30px" }}>
              Warning!!
            </h3>
            
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <button className="btn btn-blue" style={{ borderRadius: "10px" }} onClick={onConfirm}>
                Ok
              </button>
            </div>
          </div>}
          </Fade>
          <Fade in={open}>
          {Count === 3 && <div className={classes.paper} style={{ textAlign: "justify" }}>
            <h3 id="transition-modal-title" style={{ color: '#6f2981', marginBottom: "30px" }}>
              Warning!!!
            </h3>
            
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <button className="btn btn-blue" style={{ borderRadius: "10px" }} onClick={onConfirm}>
                Ok
              </button>
            </div>
          </div>}
          </Fade>
          <Fade in={open}>
          {Count === 4 && <div className={classes.paper} style={{ textAlign: "justify" }}>
            <h3 id="transition-modal-title" style={{ color: '#6f2981', marginBottom: "30px" }}>
              We are submitting your test 
            </h3>
            
          </div>}
           */}
        {/* </Fade> */}
      </Modal>
    </div>
  );
}
