import React from 'react';
import { withStyles, makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);




const useStyles = makeStyles({
  table: {
    minWidth: 340,
    // overflow:"scroll",
  },
  root: {
    display: 'flex',
    overflow: 'scroll',
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5
  }
  
});

export default function CustomizedTables(props) {
  const classes = useStyles();
  const {tableData, typeButton, buttonName} = props;

  return (
    <div className="listing_div">
        <div className="list_head">
  <p>
  { 
              tableData && tableData['tableHeaders'] !== undefined ? 
              tableData['tableHeaders'].split(',').map((item,index) => {
                
                return ( <span style={{lineHeight:'2',display:index === undefined ? "none" :""}} className={index === 0? "srno": index === 1?"name":index === 2?"status":index === 3?"time":index === 4?"list_action":""} key={index}>{item}</span>)
              })
              :
              <></>
            }
  </p>
</div>
          {
            tableData && tableData['tableData'] !== undefined ? 
              (
                tableData.tableData.map((row, index) => {
                    return(
                      <div className={index % 2 != 0 ? "list_even list_name" : "list_odd list_name"  }  >
                   <div className="list_detail">
                      <span className="srno">{index+1}</span> 
                      <span className="name">{row.name}</span>
                      <span className="status"><strong className="viewInMob">Status - </strong>{row.status}</span> 
                      <span className="time">{new Date(row.date).toLocaleDateString('en-GB', {day: 'numeric', month: 'short', year: 'numeric'}).replace(/ /g, '-')+"   "}{new Date(row.date).toLocaleTimeString([],{hour:'2-digit',minute:'2-digit',hour12:true})}</span>
                      </div>
                      <div className="list_action"> 
                    {row.action}
                   </div>
                 </div>
                        );
                })
              )
            :
            <><div className="list_odd list_name" style={{padding:'0px',lineHeight:'3'}}  >
            <div className="list_detail" style={{textAlign:'center'}}>
            <span style={{fontSize:'15px',fontWeight:'bold'}}>{typeButton === 'FeedbackExpired' ? 'No Expired Feedback' : typeButton === 'SurveyExpired' ? 'No Expired Survey' : 'No Expired Tests'}</span> 
            </div>
            </div></>
          }
        
</div>
  );
}
