import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom'; // Use BrowserRouter instead of Router and history
import UserRoutes from './Routes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';


function App() {
  const [themeType, setThemeType] = useState('light');

  function changeTheme(selectColor) {
    setThemeType(selectColor === 'dark' ? 'light' : 'dark');
  }

  let theme = createTheme({
    palette: {
      primary: {
        light: '#a057b1',
        main: '#6f2981',
        dark: '#400054',
      },
      secondary: {
        light: '#a057b1',
        main: '#6f2981',
        dark: '#400054',
      },
      info: {
        light: '#ffffff',
        main: '#e5e6e9',
        dark: '#b3b4b7',
      },
      mode: themeType, // Use 'mode' instead of 'type' for theme switching
    },
    typography: {
      fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <UserRoutes themeType={themeType} changeTheme={changeTheme} />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
