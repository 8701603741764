import React, { useState } from 'react';

import { makeStyles, useTheme } from "@mui/styles";
// import Paper from '@mui/material/Paper';
// import cache from 'memory-cache';
// import JoditEditor from "jodit-react";

import { generate, currentTime } from './words';
import useKeyPress from './useKeyPress';
import some from 'lodash/fp/some'
import TextField from '@mui/material/TextField';

import { Popover, Typography } from '@mui/material';
import { isSetSinkIdSupported } from 'rtcmulticonnection';
import onWindowReload from '../../utils/reloadFunction';
import supportedFileExtensions from '../Test/QuestionAnswerType/supportedFileExtensions';
import { v1_question_images_link } from '../Test/QuestionAnswerType/v1-links';
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  popover: {
    pointerEvents: 'none',
  },
}));

export default (props) => {

  const classes = useStyles();
  const theme = useTheme();

  const { testData, questionNo, setClearFlag, clearFlag, examId, setCheckFlag, examDetail, disable, setDisable,windowSwitchCount,setWindowSwitch ,setTestData} = props;
  const initialWords = testData[questionNo - 1].qtext;
  const [leftPadding, setLeftPadding] = useState(
    new Array(20).fill(' ').join(''),
  );
  const [outgoingChars, setOutgoingChars] = useState('');
  const [currentChar, setCurrentChar] = useState(initialWords.charAt(0));
  const [incomingChars, setIncomingChars] = useState(initialWords.substr(1));

  const [startTime, setStartTime] = useState();
  const [wordCount, setWordCount] = useState(0);
  const [wpm, setWpm] = useState(0);

  const editor = React.useRef(null);

  const [accuracy, setAccuracy] = useState(0);
  const [typedChars, setTypedChars] = useState('');

  const [content, setContent] = useState('');
  const [questionContent, setQuestionContent] = useState(testData[questionNo - 1].qtext.split('|')[0]);

  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [skippedCount, setskippedCount] = useState(0);
  const [buttonStyle, setbuttonStyle] = useState('');
  const [keyStrokes,setKeyStrokes] = React.useState(0);
  const config = {
    readonly: false // all options from https://xdsoft.net/jodit/doc/
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const onAnswerText = (content) => {
    setClearFlag(false);
    setCheckFlag(false);

    // testData[questionNo -1].selectedChoiceId = content;

    // if(content !== ''){
    //     testData[questionNo -1].buttonStyle = 'Answered';

    // }else{
    //     testData[questionNo -1].buttonStyle = 'Not Answered';

    // }

    setContent(content);
  }

  onWindowReload(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData)

  React.useEffect(() => {
    //  useKeyPress()
    setOutgoingChars('')
    setContent(testData[questionNo -1].descriptiveText ? testData[questionNo -1].descriptiveText : '')
    setTypedChars(testData[questionNo -1].descriptiveText ? testData[questionNo -1].descriptiveText : '')
    setCorrectCount(testData[questionNo -1].correctCount ? testData[questionNo -1].correctCount : 0)
    setIncorrectCount(testData[questionNo - 1].incorrectCount ? testData[questionNo - 1].incorrectCount : 0)
    setWordCount(testData[questionNo -1].wordCount ? testData[questionNo - 1].wordCount : 0)
    setAccuracy(testData[questionNo - 1].typingAccuracy ? testData[questionNo - 1].typingAccuracy : 0)
    setWpm( testData[questionNo -1].wpm ? testData[questionNo -1].wpm : 0)
    if (questionNo == testData.length ) {

      setDisable(true);
    }

    setQuestionContent( testData[questionNo -1].questionContent ? testData[questionNo - 1].questionContent : questionContent)
    // setQuestionContent(testData[questionNo - 1].qtext.split('|')[0]);
    // if(testData[questionNo -1].descriptiveText !== '' || testData[questionNo -1].descriptiveText !== null || testData[questionNo -1].descriptiveText !== 'null'){
    // setContent(testData[questionNo - 1].descriptiveText);
    // setTypedChars(testData[questionNo - 1].descriptiveText);
    // }
    let collectData = {quesData: testData, examDetail: examDetail}
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
    window.localStorage.setItem(examId, encode);
  }, [questionNo, disable,clearFlag,testData]);

  useKeyPress((questionNo), content, (key) => {
    setKeyStrokes(keyStrokes + 1)
    if (!startTime) {
      setStartTime(currentTime());
    }

    let updatedOutgoingChars = outgoingChars;
    let updatedIncomingChars = incomingChars;
    let updatedTypedChars = typedChars === null ? updatedOutgoingChars + key : typedChars + key;
    if (key === "Backspace") {

      updatedTypedChars = typedChars.substring(0,typedChars.length- 1);
      // setTypedChars(updatedTypedChars);

      // setContent(updatedTypedChars);
      setTypedChars(content[content.length - 1] === ' ' ? typedChars : updatedTypedChars)
      setContent(content[content.length - 1] === ' ' ? typedChars : updatedTypedChars)
      setKeyStrokes(keyStrokes - 1)
    }
    else if (key != null || key != "") {
      if ((testData[questionNo - 1].qtext.split(' ').length < content.split(' ').length)) {
        setTypedChars(typedChars);
        setContent(typedChars);
      } else {
        setTypedChars(updatedTypedChars);
        setContent(updatedTypedChars);
      }
    }
    else {
      setTypedChars("");

      setContent("");
    }
    let updatedContent = "", tempIndex;
    // old logic
    // for(let i = 0; i < content.split(" ").length; i++){
    //   let quesItem = testData[questionNo -1].qtext.split(" ")[i];
    //   let nextquesItem = testData[questionNo -1].qtext.split(" ")[i+1];
    //   let contentItem = content.split(" ")[i];
    //   if(quesItem === contentItem){
    //     updatedContent += `<strong style="color:green;">${contentItem}</strong> `
    //     setCorrectCount(updatedContent.split("color:green;", -1).length-1)
    //     tempIndex = i;
    //   }else{
    //     let skippedWord = "", flag = false;
    //     for(let j = i; j < testData[questionNo -1].qtext.split(" ").length; j++){
    //       let quesItemTemp = testData[questionNo -1].qtext.split(" ")[j];
    //       if(quesItemTemp === contentItem){
    //         flag = true;
    //         updatedContent += `<i style="color:orange; text-decoration: line-through">${skippedWord}</i> `;
    //         updatedContent += `<strong style="color:green;">${contentItem}</strong> `;
    //         setCorrectCount(updatedContent.split("color:green", -1).length-1)
    //         i = j;
    //         tempIndex = i;
    //         continue;
    //       }else{
    //         skippedWord += quesItemTemp + " ";
    //       }
    //     }
    //     if(!flag){
    //       updatedContent += `<strong style="color:red;">${quesItem}</strong> `;
    //       setIncorrectCount(updatedContent.split("color:red;", -1).length-1)
    //       tempIndex = i;
    //     }
    //   }
    // }


    // for(let i = 0; i < content.split(" ").length; i++){
    //   let quesItem = testData[questionNo -1].qtext.split(" ")[i];
    //   let nextquesItem = testData[questionNo -1].qtext.split(" ")[i+1];
    //   let contentItem = content.split(" ")[i];
    //   if(quesItem === contentItem){
    //     updatedContent += `<strong style="color:green;">${contentItem}</strong> `
    //     setCorrectCount(updatedContent.split("color:green;", -1).length-1)
    //     tempIndex = i;
    //   }
    //   else{
    //     if(contentItem === nextquesItem){
    //       updatedContent += `<strong style="color:orange; text-decoration: line-through;">${quesItem}</strong>`
    //       updatedContent += `<strong style="color:green;">${nextquesItem}</strong>`
    //       tempIndex = i + 1;
    //     }
    //     else{
    //       updatedContent += `<strong style="color:red;">${contentItem}</strong> `
    //       tempIndex = i;
    //     }
    //   }
    // }

    if (content !== null) {
    for (let i = 0; i < content.split(" ").length; i++) {
      let quesItem = testData[questionNo - 1].qtext.split(" ")[i];
      let nextquesItem = testData[questionNo - 1].qtext.split(" ")[i + 1];
      let contentItem = content.split(" ")[i];
      if (quesItem === contentItem) {
        updatedContent += `<strong style="color:green;">${contentItem}</strong> `
        setCorrectCount(updatedContent.split("color:green;", -1).length - 1)
        tempIndex = i;
      } else {

        let skippedWord = "", flag = false;

        // if(contentItem === nextquesItem){
        //   updatedContent += updatedContent += `<i style="color:orange; text-decoration: line-through">${quesItem}</i> `;
        //   // updatedContent += `<strong style="color:green;">${contentItem}</strong> `;
        //   // setCorrectCount(updatedContent.split("color:green", -1).length-1)
        //   tempIndex = i;
        // }

        // for(let j = i; j < testData[questionNo -1].qtext.split(" ").length; j++){
        //   let quesItemTemp = testData[questionNo -1].qtext.split(" ")[j];
        //   if(quesItemTemp === contentItem){
        //     flag = true;
        //     updatedContent += `<i style="color:orange; text-decoration: line-through">${skippedWord}</i> `;
        //     updatedContent += `<strong style="color:green;">${contentItem}</strong> `;
        //     setCorrectCount(updatedContent.split("color:green", -1).length-1)
        //     i = j;
        //     tempIndex = i;
        //     // continue;
        //   }else{
        //     skippedWord += quesItemTemp + " ";
        //   }
        // }
        if (!flag) {
          updatedContent += `<strong style="color:red;">${quesItem}</strong> `;
          setIncorrectCount(updatedContent.split("color:red;", -1).length - 1)
          tempIndex = i;
        }
      }
    }
  }
    updatedContent += `${testData[questionNo - 1].qtext.split(" ").slice(tempIndex + 1, (tempIndex + 1 + (1))).join(" ")}</strong> ` + " " + `${testData[questionNo - 1].qtext.split(" ").slice((tempIndex + 1) + 1, testData[questionNo - 1].qtext.split(" ").length).join(" ")}`
    setQuestionContent(`<p>${updatedContent}</p>`);
    if (key === currentChar) {

      if (leftPadding.length > 0) {
        setLeftPadding(leftPadding.substring(1));
      }
      if (currentChar === "Backspace") {
        setTypedChars(typedChars)
        setContent(typedChars)
      } else {
        updatedOutgoingChars += currentChar;
        setOutgoingChars(updatedOutgoingChars);
      }
      setCurrentChar(incomingChars.charAt(0));

      updatedIncomingChars = incomingChars.substring(1);
      if (updatedIncomingChars.split(' ').length < 10) {
        updatedIncomingChars += ' ' + generate();
      }
      setIncomingChars(updatedIncomingChars);

      // if (incomingChars.charAt(0) === ' ') {
      //   console.log('hErE')
      //   setWordCount(wordCount + 1);

        let durationInMinutes = (currentTime() - startTime) / 60000.0;
        // console.log('durationInMinutes',testData[questionNo - 1].timeSpend)
        setWpm(((wordCount ) / durationInMinutes).toFixed(2));
      // }
      // else {

    //    setWordCount(correctCount + incorrectCount);
      // }
    }
    setWordCount(content && content.split(' ').length)
    setAccuracy(
      ((updatedOutgoingChars.length * 100) / updatedTypedChars.length).toFixed(
        2,
      ),
    );

    let speed = testData[questionNo -1].timeSpend > 0 ?  (wordCount / (testData[questionNo -1].timeSpend / 60)) : 0;
    let netWPM = testData[questionNo -1].timeSpend > 0 ?  (((wordCount) - (incorrectCount + skippedCount)) / (testData[questionNo -1].timeSpend / 60)) : 0
    let typingAccuracy =  (((netWPM / speed) * 100).toFixed(2))

    // testData[questionNo - 1].selectedChoiceId = content ?  content + "|" + netWPM + "|" + typingAccuracy : '';
    testData[questionNo - 1].selectedChoiceId = content ?  content : ""
    testData[questionNo - 1].descriptiveText = content ? content : '';
    testData[questionNo - 1].buttonStyle = 'Answered';
    testData[questionNo - 1].wordCount = wordCount ? wordCount : 0;
    testData[questionNo - 1].correctCount = correctCount ? correctCount : 0;
    testData[questionNo - 1].incorrectCount = incorrectCount ? incorrectCount : 0;
    testData[questionNo - 1].skippedCount = skippedCount ? skippedCount : 0;
    testData[questionNo - 1].wpm = netWPM ?  netWPM : 0;
    testData[questionNo - 1].typingAccuracy = typingAccuracy ? typingAccuracy : 0;
    testData[questionNo - 1].keyStrokes = keyStrokes ? keyStrokes : 0;
    testData[questionNo - 1].questionContent = questionContent;

    let collectData = {quesData: testData, examDetail: examDetail}
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
    window.localStorage.setItem(examId, encode);

  }, [questionNo, content, questionContent, wordCount, typedChars, testData[questionNo - 1].qid], buttonStyle, correctCount, incorrectCount, skippedCount);

  return (
    <>
      {/* <strong> Question No {questionNo}</strong>< br/>  */}
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <span className="question-num">
          Q . {questionNo} of {testData.length}
        </span>
      </Typography>
      {/* <Button> Question No {questionNo}</Button>< br/>  */}
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        // PaperProps={{
        //   style: { height: '4%' ,textAlign:"center"},
        // }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <h5>{testData[questionNo - 1].qid}</h5>
      </Popover>
      {
        content && content.split(" ").length > 1
          ?
          <span style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: questionContent }} />
          :
          <span style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }} />
      }
      {
        ((testData[questionNo -1].qresourcepath.split('|^^|')[0] !== undefined && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== null && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== "") ||
          (testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "")) ? (
          <div>
            <br />
            {supportedFileExtensions.image.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
              <img
                src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                  testData[questionNo -1].question_resource_url.split('|^^|')[0]
                  :
                  v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                }
                key={testData[questionNo -1].qid}
                style={{ maxWidth: "100%" }}
                alt=""
              />
              : supportedFileExtensions.audio.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                <audio
                  controls
                  src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                    testData[questionNo -1].question_resource_url.split('|^^|')[0]
                    :
                    v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                  }
                  key={testData[questionNo -1].qid}
                  style={{ maxWidth: "100%" }}
                  alt=""
                />
                : supportedFileExtensions.video.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                  <video
                    controls
                    src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                      testData[questionNo -1].question_resource_url.split('|^^|')[0]
                      :
                      v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                    }
                    key={testData[questionNo -1].qid}
                    style={{ maxWidth: "100%" }}
                    alt=""
                  />
                  : supportedFileExtensions.file.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) ?
                    <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                      testData[questionNo -1].question_resource_url.split('|^^|')[0]
                      :
                      v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                    }
                      download>CLICK TO DOWNLOAD</a>
                    :
                    null
            }
          </div>
        ) : (
          <></>
        )
      }
      {/* <p style={{whiteSpace: 'pre'}}>
                <span style={{color: 'silver'}}>
                {(leftPadding + outgoingChars).slice(-20)}
                </span>
                <span style={{backgroundColor: '#09d3ac'}}>{currentChar}</span>
                <span>{incomingChars.substr(0, 20)}</span>
            </p> */}
      {/* <JoditEditor
            	ref={editor}
                value={content}
                config={config}
                tabIndex={1} // tabIndex of textarea
                //onBlur={newContent => onAnswerText(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => {}}
            /> */}
      <TextField
        id="outlined-multiline-static"
        label="Answer"
        multiline
        // value={content == '' ? '':content}
        value={content || ''}
        rows={6}
        // defaultValue="Default Value"
        variant="outlined"
        style={{ width: '100%' }}
      />
      {/* <h3>
             WPM: {wpm} | ACC: {accuracy}%
            </h3> */}
      {/* <h3>
        correctCount: {correctCount} | incorrectCount: {incorrectCount} | skippedCount : {skippedCount} | wordCount : {wordCount}
      </h3> */}
    </>
  );
}
