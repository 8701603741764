import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashboardService from "../service/dashboardService";

const initialState = {
  buttonArray: [],
  typeButton: window.sessionStorage.getItem("typeButton") ? window.sessionStorage.getItem("typeButton") : "",
  userHome: null,
  groups: null,
  testType: [],
  orgId: window.sessionStorage.getItem("orgId") ? window.sessionStorage.getItem("orgId") : "",
  orgData: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  selectTypeButton: null,
  testData: [],
};

export const addUserHome = createAsyncThunk("dashboard/addUserHome", async (data, thunkAPI) => {
  try {
    return await dashboardService.API_USER_HOME(data);
  } catch (error) {
    const message = error.response.data.msg;
    return thunkAPI.rejectWithValue(message);
  }
});

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setButtonArray(state, action) {
      state.buttonArray = action.payload;
    },
    setUserHomeDetails(state, action) {
      state.userHome = action.payload;
    },
    setGroupDetails(state, action) {
      state.groups = action.payload;
    },
    setType(state, action) {
      state.testType = action.payload;
    },
    setSelectedType(state, action) {
      state.selectTypeButton = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = false;
    },
    setOrgId(state, action) {
      state.orgId = action.payload;
    },
    setOrgData(state, action) {
      state.orgData = action.payload;
    },
    setTypeButton(state, action) {
      state.typeButton = action.payload;
    },
    setTestData(state, action) {
      state.testData = action.payload;
    },
  },

  /*
  extraReducers: {
    // userHome API
    [addUserHome.pending]: (state) => {
      state.isLoading = true;
    },
    [addUserHome.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [addUserHome.rejected]: (state) => {
      state.isLoading = false;
    },
  },
  */
});

export const {
  setButtonArray,
  setUserHomeDetails,
  setGroupDetails,
  setType,
  setSelectedType,
  setLoading,
  setOrgId,
  setTypeButton,
  setTestData,
  setOrgData,
} = dashboardSlice.actions;

export const getButtonArray = (state) => {
  return state.dashboard.buttonArray;
};
export const getLoading = (state) => {
  return state.dashboard.isLoading;
};
export const getGroupDetails = (state) => {
  return state.dashboard.groups;
};
export const getOrgId = (state) => {
  return state.dashboard.orgId;
};
export const getOrgData = (state) => {
  return state.dashboard.orgData;
};
export const getTypeButton = (state) => {
  return state.dashboard.typeButton;
};
export const getUserHomeDetails = (state) => {
  return state.dashboard.userHome;
};
export const getType = (state) => {
  return state.dashboard.testType;
};
export const getSelectedType = (state) => {
  return state.dashboard.selectTypeButton;
};
export const getTestData = (state) => {
  return state.dashboard.testData;
};

export default dashboardSlice.reducer;
