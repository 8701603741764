import React, { useEffect } from 'react';

import getHTMLMediaElement from '../../utils/getHTMLMediaElement';

import RTCMultiConnection from 'rtcmulticonnection';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import axios from '../../utils/axiosConfig';

window.io = io;

function LiveMonitoring(props) {
    

    const { examDetail } = props;
    let examId = examDetail[0].id;
    let token = Cookies.get('token');


    useEffect(() => {
        let connection = new RTCMultiConnection();
        
        // by default, socket.io server is assumed to be deployed on your own URL
        connection.socketURL = '/';

        // comment-out below line if you do not have your own socket.io server
        connection.socketURL = 'https://rtcmulticonnection.herokuapp.com:443/';

        connection.socketMessageEvent = 'video-broadcast-demo';

        connection.session = {
            audio: true,
            video: true,
            oneway: true
        };

        connection.sdpConstraints.mandatory = {
            OfferToReceiveAudio: false,
            OfferToReceiveVideo: false
        };

        connection.videosContainer = document.getElementById('videos-container');
        connection.onstream = function (event) {
           

            event.mediaElement.removeAttribute('src');
            event.mediaElement.removeAttribute('srcObject');
            event.mediaElement.muted = true;
            event.mediaElement.volume = 0;

            let video = document.createElement('video');

            try {
                video.setAttributeNode(document.createAttribute('autoplay'));
                video.setAttributeNode(document.createAttribute('playsinline'));
            } catch (e) {
                video.setAttribute('autoplay', true);
                video.setAttribute('playsinline', true);
            }

            if (event.type === 'local') {
                video.volume = 0;
                try {
                    video.setAttributeNode(document.createAttribute('muted'));
                } catch (e) {
                    video.setAttribute('muted', true);
                }
            }
            video.srcObject = event.stream;

            let width = parseInt(connection.videosContainer.clientWidth / 3) - 20;
            let mediaElement = getHTMLMediaElement(video, {
                title: { examId },
                buttons: ['full-screen'],
                width: width,
                showOnMouseEnter: true
            });

            connection.videosContainer.appendChild(mediaElement);

            setTimeout(function () {
                mediaElement.media.play();
            }, 5000);

            mediaElement.id = event.streamid;
        };

        connection.onstreamended = function (event) {
            

            let mediaElement = document.getElementById(event.streamid);
            if (mediaElement) {
                mediaElement.parentNode.removeChild(mediaElement);

                if (event.userid === connection.sessionid && !connection.isInitiator) {
                    alert('Broadcast is ended. We will reload this page to clear the cache.');
                    window.location.reload();
                }
            }
        };
        connection.open(examId);


        axios.post('/test/checkLiveProctorStatus', {
            headers: {
                'Access-Control-Allow-Origin': '*',
            }, userexamid: examId
        }).then(resp => {
            
            //  resp.data.msg === 'SUCCESS'? setHidden(false) : setHidden(true)    

        }).catch(err => {
            //    setHidden(true);
            
        })
    }, []);
    return (
        <>
            <div style={{ display: 'none' }} >

                <input type="text" id="room-id" value={examDetail[0].id} style={{ display: 'none' }} />

                <button id="open-room" style={{ display: 'none' }}>Open Room</button>
                <div id="room-urls" style={{ display: 'none' }}></div>
                <div className="container">
                    <div id="videos-container"></div>

                </div>
            </div>
        </>
    );
}

export default LiveMonitoring;