import React from 'react';
import { makeStyles, useTheme } from "@mui/styles";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    title: {
      flexGrow: 1,
    }
}));


export default (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const {examDetail, questionNo, testData} = props;

    return (
        <AppBar position="static" color="info">
            <Toolbar variant="">
            
            
            {/* <Typography >{(examDetail[0].showcategoryflag ? `Category : ${testData[questionNo -1].categoryname} | ` : '')}{(examDetail[0].showdifficultyflag ? `Difficulty Level : ${testData[questionNo -1].qdifficultylevelid === '1' ? examDetail[0].beginnerlabel : testData[questionNo -1].qdifficultylevelid === '2' ? examDetail[0].intermediatelabel : examDetail[0].advancelabel} | ` : '')}{examDetail[0].showmarksinexamflag ? `Marks Allocated : ${testData[questionNo -1].qmarks} | ` : ''}{examDetail[0].showmaxtimeinexamflag ? `Ideal Time : ${testData[questionNo -1].qdifficultylevelid === '1' ? examDetail[0].beginnertime : testData[questionNo -1].qdifficultylevelid === '2' ? examDetail[0].intermediatetime : examDetail[0].advancetime} Minute(s)` : ''}</Typography> */}
           {
             examDetail[0].showcategoryflag ?
<Typography dangerouslySetInnerHTML={{__html: ' Category :&nbsp; '}} />
:<></>
           } 
            <Typography style={{fontSize:'12px'}} >{(examDetail[0].showcategoryflag ? `${testData[questionNo -1].categoryname}` : '')}</Typography>
           {
             examDetail[0].showdifficultyfla ?
<Typography dangerouslySetInnerHTML={{__html: '&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Difficulty Level :&nbsp;'}} />
:<></>
           }
            
            <Typography style={{fontSize:'12px'}} >{(examDetail[0].showdifficultyflag ? ` ${testData[questionNo -1].qdifficultylevelid === '1' ? examDetail[0].beginnerlabel : testData[questionNo -1].qdifficultylevelid === '2' ? examDetail[0].intermediatelabel : examDetail[0].advancelabel}` : '')}</Typography>
            {
              examDetail[0].showmarksinexamflag ?
<Typography dangerouslySetInnerHTML={{__html: '&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Marks Allocated : &nbsp;'}} />
:<></>
            }
            
            <Typography style={{fontSize:'12px'}} >{examDetail[0].showmarksinexamflag ? `${testData[questionNo -1].qmarks}` : ''}</Typography>
            {
              examDetail[0].showmaxtimeinexamflag?
<Typography dangerouslySetInnerHTML={{__html: '&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;Ideal Time : &nbsp;'}} />
:<></>
            }
            
            <Typography style={{fontSize:'12px'}} >{examDetail[0].showmaxtimeinexamflag ? `${testData[questionNo -1].qdifficultylevelid === '1' ? examDetail[0].beginnertime : testData[questionNo -1].qdifficultylevelid === '2' ? examDetail[0].intermediatetime : examDetail[0].advancetime} Minute(s)` : ''}</Typography>
            </Toolbar>
        </AppBar>
    );
}