import React, {useEffect} from "react";
import { makeStyles } from '@mui/styles';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    width: "380px"
  }
}));

export default function TransitionsModal({ onConfirm, open, Count }) {

  const classes = useStyles();
  

  return (
    <div>
      <Modal
        open={open}
        disableEnforceFocus={true}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
        <div className={classes.paper} style={{ textAlign: "justify" }}>
            <h3 id="transition-modal-title" style={{ color: '#6f2981', marginBottom: "30px", textAlign: 'center' }}>
              Face not matched 
            </h3>
            <p id="transition-modal-description">
            Your photo does not match. You cannot proceed further.
          </p>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <button className="btn btn-blue" style={{ borderRadius: "10px" }} onClick={onConfirm}>
              Ok
            </button>
          </div>
          </div>
          </Fade>
      </Modal>
    </div>
  );
}
