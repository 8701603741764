import { useState, useEffect } from 'react';

const useKeyPress = (questionNo,content,callback) => {
  
  const [keyPressed, setKeyPressed] = useState();
  

  useEffect(() => {
    const downHandler = ({ key }) => {
      
      if (keyPressed !== key && key.length === 1) {
        setKeyPressed(key);
        callback && callback(key);
      }else if(key === 'Backspace' ){
          setKeyPressed(key);
        callback && callback(key);
      }
    };
  
    const upHandler = () => {
      setKeyPressed(null);
    };

  
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
  
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  },[questionNo,content,callback]);
  
  return keyPressed;
};

export default useKeyPress;