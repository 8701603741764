import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from "jodit-react";
import { makeStyles, useTheme } from "@mui/styles";
// import Paper from '@mui/material/Paper';
import cache from 'memory-cache';
import { Popover, Typography } from '@mui/material';
import { WEB_URL } from '../../../utils/constants';
import onWindowReload from '../../../utils/reloadFunction';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from '@mui/material/Button';
import axios from '../../../utils/axiosConfig';
import supportedFileExtensions from './supportedFileExtensions'
import { v1_question_images_link } from './v1-links';
import { translationMissingMessage } from './translationConstants';
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: '0px 5px',
  },
}));

export default (props) => {
  const classes = useStyles();
  // const theme = useTheme();

  const { testData, questionNo, setClearFlag, clearFlag, examId, setCheckFlag, examDetail, disable, setDisable, setTestData, windowSwitchCount, setWindowSwitch } = props;
  const editor = useRef(null)

  // const [content, setContent] = useState('') /*for Jodit Editor */
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [file, setFile] = useState();
  const [uploadedFile,setUploadedFile] = useState()
  const [base64,setBase64] = useState()
  const [contentType,setContentType] = useState()
  const [errorMessage,setErrorMessage] = useState()
  const [defaultMsg,setDefaultMsg] = useState()
  const [checkPreviewState,setCheckPreviewState] = useState(false)

  const onEditorStateChange = (editorState) => {
    setClearFlag(false);
    if(draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(/(<\/?[^>]+(>|$)|&nbsp;|\s)/g, "") != "") {
      setCheckFlag(true)
    }
    else {
      setCheckFlag(false);
    }
    testData[questionNo - 1].descriptiveText = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    testData[questionNo - 1].selectedChoiceId = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorState(editorState)
    let collectData = { quesData: testData, examDetail: examDetail }
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
    window.localStorage.setItem(examId, encode);
  }

  /* Jodit config 
    const editorConfig = { 
      removeButtons: ['source', 'fontsize', 'about', 'paint format', 'paint', 'hr', 'image', 'video', 'symbol', 'ul', 'file', 'link', 'paste', 'print'],
      readonly: false,
      toolbar: true,
      spellcheck: true,
      language: "en",
      toolbarButtonSize: "medium",
      toolbarAdaptive: false,
      showCharsCounter: true,
      showWordsCounter: true,
      showXPathInStatusbar: false,
      askBeforePasteHTML: true,
      askBeforePasteFromWord: true,
      //defaultActionOnPaste: "insert_clear_html",
      uploader: {
        insertImageAsBase64URI: true
      },
      autofocus: {
        cursorAfterAutofocus: 'end'
      },
      width: 'auto',
      height: 'auto',
    };
  */
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

    onWindowReload(examId, examDetail, setWindowSwitch, setTestData, windowSwitchCount, testData)

  const open = Boolean(anchorEl);


/* Jodit editor onBlur 
  const onAnswerText = (content) => {
    setClearFlag(false);
    setCheckFlag(false);

    testData[questionNo - 1].selectedChoiceId = content.trim();
    testData[questionNo - 1].descriptiveText = content.trim();

    if (content !== '') {
      testData[questionNo - 1].buttonStyle = 'Answered';

    } else {
      testData[questionNo - 1].buttonStyle = 'Not Answered';

    }

    setContent(content.trim());
  }
*/
  React.useEffect(() => {
    // testData[questionNo - 1].descriptiveText = ""
    if (questionNo === testData.length) {
      setDisable(true);
    }
    setEditorState(EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(testData[questionNo - 1].selectedChoiceId))))
        testData[questionNo -1].contentType != undefined && testData[questionNo -1].contentType != '' ? 
        setContentType(testData[questionNo -1].contentType) : setContentType();
        testData[questionNo -1].uploadedFile != undefined && testData[questionNo -1].contentType != '' ? 
        setUploadedFile(testData[questionNo -1].uploadedFile) : setUploadedFile()
        testData[questionNo -1].base64 != undefined && testData[questionNo -1].base64 != '' ? 
        setBase64(testData[questionNo -1].base64) : setBase64()

        if(testData[questionNo -1].contentType != "" && testData[questionNo - 1].uploadedFile != "" ){
          setDefaultMsg('You can check already uploaded file here...')
          setCheckPreviewState(false) 
        }else {
          setDefaultMsg('')
          setCheckPreviewState(true)
        }


    /* for Jodit default state
        // setContent(testData[questionNo - 1].selectedChoiceId); 
        // testData[questionNo - 1].descriptiveText = content.trim();
     */

    if (clearFlag) {
      // testData[questionNo -1].descriptiveText = "";

      testData[questionNo - 1].selectedChoiceId = "";
      setEditorState(EditorState.createEmpty())
    testData[questionNo -1].contentType = '';
    testData[questionNo -1].uploadedFile = ''
    testData[questionNo -1].base64 = ''
    setBase64()
    setContentType()
    setUploadedFile()
      // setContent("");
      testData[questionNo - 1].buttonStyle = 'Not Answered';
      let collectData = { quesData: testData, examDetail: examDetail }
      let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
      window.localStorage.setItem(examId, encode);
      setClearFlag(false);
    }
    let collectData = { quesData: testData, examDetail: examDetail }
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
    window.localStorage.setItem(examId, encode);
  }, [questionNo, clearFlag])

  const downloadLink = (e) => {
    e.preventDefault();
    window.open(e.target.href, '_blank');
  }

  /* on uploading File */
  const getUploadData = (e) => {
    testData[questionNo -1].contentType = '';
    testData[questionNo -1].uploadedFile = ''
    testData[questionNo -1].base64 = ''
    setErrorMessage()
    setCheckPreviewState(false)
    setDefaultMsg()
    setBase64()
    setContentType()
    setUploadedFile()
    let file = e.target.files[0];
    if (file !== '' && file !== undefined) {
      if(file.size <  5 * 6000000){
        testData[questionNo - 1].file = file;
        setFile(file);
      }else {
        testData[questionNo - 1].file = ''
        setFile()
        setErrorMessage("File size must be less than 5MB...")
      }
        
    }
    else{
      testData[questionNo - 1].file = ''
      setFile()
    }
    let collectData = { quesData: testData, examDetail: examDetail }
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
    window.localStorage.setItem(examId, encode);
  }

  /* on upload button click */
  const onUploadFile = (e)=>{
    let formData = new FormData();
    formData.append('upl', file);
      axios({
        method: "POST",
        url: "/uploadFile",
        data: formData,
        headers: {'Access-Control-Allow-Origin': '*', "Content-Type": "multipart/form-data",
        'newfilename' : `${examDetail[0].id}_${testData[questionNo - 1].qid}_v2`}
      }).then(response => {
        testData[questionNo -1].file = file;
        testData[questionNo -1].contentType = response.data.file.contentType;
        testData[questionNo -1].uploadedFile = response.data.file.filename;
        setUploadedFile(response.data.file.filename)
        setContentType(response.data.file.contentType)
        let collectData = { quesData: testData, examDetail: examDetail }
        let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
        window.localStorage.setItem(examId, encode);
      }).catch(error => {
          console.log("Error onUploadFile", error);
      });

  }

  const onpreviewFile = (e) => {
    setCheckPreviewState(true)
    if(base64 == undefined){    
    axios.post(`/readFile`,{  
      headers: {
        'Access-Control-Allow-Origin': '*',
      },data : uploadedFile
    }).then(response =>{
      testData[questionNo - 1].file = file;
      testData[questionNo -1].base64 = response.data.base64Data;
      let base64Data = response.data.base64Data;
      setBase64(base64Data)
  }).catch(error => {
    console.log('error',error); 
  })
  let collectData = { quesData: testData, examDetail: examDetail }
  let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
  window.localStorage.setItem(examId, encode);
}
  }

  const checkContent = (type) => {
    if (type == 'video/avi' ||
      type == 'video/x-flv' ||
      type.includes('application/vnd.') ||
      type == 'application/zip' ||
      type == 'application/octet-stream') {
      return true
    } else {
      return false
    }
  }

  return (

    <>
      {/* <strong> Question No {questionNo}</strong>< br/>  */}
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <span className="question-num">Q.{questionNo} of {testData.length}</span>
      </Typography>
      {/* <Button> Question No {questionNo}</Button>< br/>  */}
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        // PaperProps={{
        //   style: { height: '4%' ,textAlign:"center"},
        // }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <h5>{testData[questionNo - 1].qid}</h5>
      </Popover>
      {examDetail[0].comprehensionflag && testData[questionNo - 1].passageheader ?
        <>{(examDetail[0].selectedLanguage == null || examDetail[0].selectedLanguage == "en-IN" || examDetail[0].include_english == true) || (examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && testData[questionNo - 1].translated_passageheader == null) ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].passageheader }}/> : null}
        {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_passageheader == null) ? <><br /><br /></> : null}
        {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].translated_passageheader || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_qtext ? "" : "10px" }} /> : null}</> :
        <>{(examDetail[0].selectedLanguage == null || examDetail[0].selectedLanguage == "en-IN" || examDetail[0].include_english == true) || (examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && testData[questionNo - 1].translated_passageheader == null) ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }} /> : null}
        {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_qtext == null) ? <><br /><br /></> : null}
        {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" ? <span dangerouslySetInnerHTML={{__html:testData[questionNo - 1].translated_qtext || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_qtext ? "" : "10px" }} /> : null }</>
      }
      {examDetail[0].comprehensionflag && testData[questionNo - 1].passagetext ?
        <div className="col-md-12" style={{ marginTop: "1rem", borderRadius: '5px' }} >
          {/* <p>
            <b>Passage</b>
          </p> */}
          <div className="question-para" >
            {(examDetail[0].selectedLanguage == null || examDetail[0].selectedLanguage == "en-IN" || examDetail[0].include_english == true) || (examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && testData[questionNo - 1].translated_passagetext == null) ? <span  dangerouslySetInnerHTML={{__html: testData[questionNo -1].passagetext}} /> : null}
            {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_passagetext == null) ? <><br /><br /></> : null}
            {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" ? <span  dangerouslySetInnerHTML={{__html: testData[questionNo -1].translated_passagetext || translationMissingMessage}} style={{ fontSize: testData[questionNo - 1].translated_passagetext ? "" : "10px" }} /> : null}
          </div>
          {((testData[questionNo -1].passageImage !== undefined && testData[questionNo -1].passageImage !== null && testData[questionNo -1].passageImage !== "") ||
            (testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "")) ? (
            <div>
              <br />
              {supportedFileExtensions.image.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                <img
                  src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                    testData[questionNo -1].passageImageURL
                    :
                    v1_question_images_link + testData[questionNo -1].passageImage
                  }
                  key={testData[questionNo -1].qid}
                  style={{ maxWidth: "100%" }}
                  alt=""
                />
                : supportedFileExtensions.audio.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                  <audio
                    controls
                    src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                      testData[questionNo -1].passageImageURL
                      :
                      v1_question_images_link + testData[questionNo -1].passageImage
                    }
                    key={testData[questionNo -1].qid}
                    style={{ maxWidth: "100%" }}
                    alt=""
                  />
                  : supportedFileExtensions.video.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                    <video
                      controls
                      src={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                        testData[questionNo -1].passageImageURL
                        :
                        v1_question_images_link + testData[questionNo -1].passageImage
                      }
                      key={testData[questionNo -1].qid}
                      style={{ maxWidth: "100%" }}
                      alt=""
                    />
                    : supportedFileExtensions.file.includes(testData[questionNo -1].passageImage.split('.')[testData[questionNo -1].passageImage.split('.').length - 1].toLowerCase()) ?
                      <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].passageImageURL !== undefined && testData[questionNo -1].passageImageURL !== null && testData[questionNo -1].passageImageURL !== "") ?
                        testData[questionNo -1].passageImageURL
                        :
                        v1_question_images_link + testData[questionNo -1].passageImage
                      }
                        download>CLICK TO DOWNLOAD</a>
                      :
                      null
              }
              <br/><br />
            </div>
          ) : (
            <></>
          )}
        </div>
        : 
        ((testData[questionNo -1].qresourcepath.split('|^^|')[0] !== undefined && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== null && testData[questionNo -1].qresourcepath.split('|^^|')[0] !== "") ||
          (testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "")) ? (
          <div className='col-md-12'>
            {supportedFileExtensions.image.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) && testData[questionNo -1].qtypecode != '25' ?
              <img
                src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                  testData[questionNo -1].question_resource_url.split('|^^|')[0]
                  :
                  v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                }
                key={testData[questionNo -1].qid}
                style={{ maxWidth: "100%" }}
                alt=""
              />
              : supportedFileExtensions.audio.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) && testData[questionNo -1].qtypecode != '25' ?
                <audio
                  controls
                  src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                    testData[questionNo -1].question_resource_url.split('|^^|')[0]
                    :
                    v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                  }
                  key={testData[questionNo -1].qid}
                  style={{ maxWidth: "100%" }}
                  alt=""
                />
                : supportedFileExtensions.video.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) && testData[questionNo -1].qtypecode != '25' ?
                  <video
                    controls
                    src={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                      testData[questionNo -1].question_resource_url.split('|^^|')[0]
                      :
                      v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                    }
                    key={testData[questionNo -1].qid}
                    style={{ maxWidth: "100%" }}
                    alt=""
                  />
                  : supportedFileExtensions.file.includes(testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.')[testData[questionNo -1].qresourcepath.split('|^^|')[0].split('.').length - 1].toLowerCase()) || testData[questionNo -1].qtypecode == '25' ?
                    <a style={{ color: "#337ab7", fontSize: "16px" }} href={(testData[questionNo -1].question_resource_url.split('|^^|')[0] !== undefined && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== null && testData[questionNo -1].question_resource_url.split('|^^|')[0] !== "") ?
                      testData[questionNo -1].question_resource_url.split('|^^|')[0]
                      :
                      v1_question_images_link + testData[questionNo -1].qresourcepath.split('|^^|')[0]
                    }
                      download>CLICK TO DOWNLOAD</a>
                    :
                    null
            }
          </div>
        ) : (
          <></>
        )

      }
      <div className="col-md-12">
        {examDetail[0].comprehensionflag && testData[questionNo - 1].passagetext ? <p><b>Question</b></p> : " "}
        <div className="form-group question-right">
          {examDetail[0].comprehensionflag && testData[questionNo - 1].passagetext ?
            <>

              <label className="control-label">

              {(examDetail[0].selectedLanguage == null || examDetail[0].selectedLanguage == "en-IN" || examDetail[0].include_english == true) || (examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && testData[questionNo - 1].translated_qtext == null) ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }} /> : null}
              {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && (examDetail[0].include_english == true || testData[questionNo - 1].translated_qtext == null) ? <><br /><br /></> : null}
              {examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" ? <span dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].translated_qtext || translationMissingMessage }} style={{ fontSize: testData[questionNo - 1].translated_qtext ? "" : "10px" }} /> : null}
              </label>
            </> : <></>}
            {testData[questionNo - 1].qtypecode !== '25' ? 
          <div className='editor-main' style={{ border: '1px solid #e8eafa' }}>

            {/* for Jodit editor
             <JoditEditor 
              ref={editor}
              value={content || ''}
              config={editorConfig}
              //contentEditable={true}
              // tabIndex={5} // tabIndex of textarea
              onBlur={newContent => onAnswerText(newContent)} // preferred to use only this option to update the content for performance reasons
            // onChange={newContent => { }}
            />  */}

            {/* react-draft-wysiwyg editor */}

            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
              handlePastedText={true}
              toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history']
              }}
            />

          </div>:
          <>  
          <div className='upload-file'>
              <div className='col-md-8' style={{padding:"initial"}}>
              <input
                id="file-upload"
                type='file'
                className="form-control"
                // accept=".pdf,.doc,.docx"
                onChange={(e) => getUploadData(e)
                }
              />
              </div>
              <div className='col-md-4'>
              <button name='upload-button' id='upload-button' className="btn btn-blue" style={{marginTop:'0'}} onClick = {(e)=> onUploadFile(e)} disabled={file === '' || file === undefined || contentType != undefined ? true : false}>UPLOAD FILE</button>
              </div>
          </div>
            <br />
            <br />
              {errorMessage != undefined ?
                <>
                <strong><span style={{ color: 'red' }}>
                  {errorMessage}
                </span>
                </strong>
                <br />
                <br />
                </> : ''
            }
            {contentType && (contentType == 'video/mp4' || contentType == 'audio/mpeg' || contentType.includes('image/') || contentType == 'application/pdf' || contentType == 'text/html' || contentType == 'video/x-flv') ? 
            <div className= 'preview'>
             <strong><span  style={{ color : 'green'}}>
                  {defaultMsg != undefined ? defaultMsg :  'File uploaded Successfully !!!'} 
                  </span></strong>
              <br />
              <br />
            <button name='preview-button' id='preview-button' className="btn btn-blue" style={{marginTop:'0'}} onClick = {(e)=> onpreviewFile(e)} disabled = {checkPreviewState == true ? true : false} >PREVIEW FILE</button>
            </div> :
            contentType && (checkContent(contentType) == true  || contentType == 'text/csv' ) ?
            <div className= 'download'>
               <strong>
                <span  style={{ color : 'green'}}>
                {defaultMsg != undefined ? defaultMsg :  'File uploaded Successfully !!!'} 
                </span>
                </strong>
                <br />
                <br />
            <button name='download-button' id='download-button' className="btn btn-blue" style={{marginTop:'0'}} onClick = {(e)=> onpreviewFile(e)} disabled = {checkPreviewState == true ? true : false} >DOWNLOAD FILE</button>
            </div> 
            : ''
            }
              {base64 != undefined && (contentType == 'video/mp4' ) ?
                <video width="100%" height="35rem" style={{ height: '35rem' }} controls>
                  <source src={`${base64}`} type={`${contentType}`} />
                  Your browser does not support the video tag.
                </video>
                :
                base64 != undefined && contentType == 'video/x-flv' ?
                <>
                  <br />
                  <span style={{ color : 'red'}}>
                    Your brower does not support this file format.
                  </span>
                </>
                  :
                base64 != undefined && contentType == 'audio/mpeg' ?
                  <> 
                  <br />
                  <audio controls>
                    <source src={`${base64}`} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                  </>
                  :
                  base64 != undefined && contentType.includes('image/') ?
                    <div className="col-md-12">
                      <>
                        <img className="img-responsive" src={`${base64}`} />
                      </>
                    </div> :
                    base64 != undefined && (contentType == 'text/html' || contentType == 'application/pdf' )
                      ?
                      <iframe width="100%" height="35rem" src={`${base64}`} style={{ height: '35rem' }} type={`${contentType}`}  className="internal">
                        <><embed src={`${base64}`} type={`${contentType}`} style={{ height: '35rem' }} />
                        </>
                      </iframe>
                        : base64 !== undefined &&( checkContent(contentType) || contentType == 'text/csv') == true ?
                        <iframe width="100%" height="35rem" src={`${base64}`} style={{ display : 'none'}} type={`${contentType}`}  className="internal">
                        <><embed src={`${base64}`} type={`${contentType}`} style={{ height: '35rem' }} />
                        </>
                      </iframe>
                        : ''
              }
              </>
           }
        </div>
      </div>
    </>
  );
}