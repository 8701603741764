import React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Table from './AdminTable';
import { LoadingSkeletonTableWise } from './LoadingSkeleton';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    btnPadding: {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        fontSize: "0.775rem"
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 80,
        padding: theme.spacing(3),
    }
}));
export default function FullWidthGrid(props) {
    const classes = useStyles();
    const {adminData,tableData,isActiveTableLoading} = props;
    const [activeUser, setActiveUser] = React.useState(0);
    const [currentUsers, setUsers] = React.useState(0);
    // const { handleDownlaodReport, process, buttonArray, started, pending, buttonName, setButtonClicked, typeButton, setSelectedType, selectTypeButton } = props;
    // // setSelectedType('COMPLETED');
    // const [tableData, setTabledata] = React.useState({});
    // const [groupIdData, setGroupIddata] = React.useState();
    // const [reportAllowed, setReportAllowed] = React.useState(false);
    // // const handleGroupClick = (item) => {
    //     setButtonClicked(item);
    //     setReportAllowed(buttonArray[selectTypeButton][item].report)
    //     setGroupIddata(buttonArray[selectTypeButton][item].groupId)
    //     if (buttonArray[selectTypeButton] !== undefined) {
    //         setTabledata(buttonArray[selectTypeButton][item]);
    //     }
    // }
    React.useEffect(()=>{
        if(props.tableData.length > 0){
            let arrActiveUSers = props.tableData.filter(item => item[1].activeTest);
            if(arrActiveUSers.length){
            setUsers(arrActiveUSers.length)
            }
        }
      })

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <b><h3 style={{ marginTop: "0px", color: "#7030a0", fontSize: "20px", marginBottom: "20px" }}>Ongoing Tests</h3></b>
            <b><h3 style={{ marginTop: "0px", color: "#7030a0", fontSize: "20px", marginBottom: "20px" }}>Total Active : {currentUsers || '0'}</h3></b>
            
            <Grid container spacing={3}>
                <Grid style={{ paddingBottom: "0px" }} item xs={12}>
                    {/* {buttonArray[selectTypeButton] !== undefined ?
                        Object.keys(buttonArray[selectTypeButton]).map((item, index) => {
                            if (item !== 'tableHeaders') {
                                return (<Button key={`completed${index}`} className={classes.btnPadding}
                                    variant="outlined" color={(buttonName === item) ? "primary" : "default"}
                                    onClick={() => handleGroupClick(item)}>
                                    {item}
                                </Button>)
                            }
                        }) :
                        <></>
                    } */}
                    {/* <Button className={classes.btnPadding}
                                    variant="outlined" color="primary"
                                    // onClick={() => handleGroupClick(item)} */}
                                    {/* > */}
                                    {/* {item} */}
                                    {/* In Memory Test
                                </Button> */}
                    { }
                </Grid>
                <Grid item xs={12}>
                    { }
                    {isActiveTableLoading ? <LoadingSkeletonTableWise />
                        : <Table adminData={adminData} setActiveUser={setActiveUser} tableData={tableData}/>
                    }
                    { }
                </Grid>
                { }
            </Grid>
            {/* {reportAllowed ?
                process ? <a style={{ color: '#31AE14', cursor: 'pointer', fontWeight: 'bold' }} onClick={(e) => handleDownlaodReport({ groupId: groupIdData })} >Click here to download the Report</a> : <span>Please wait, Your request is being processed.</span> : ''
            } */}
        </main>
    );
}