import React from 'react';
import { makeStyles, useTheme } from "@mui/styles";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    title: {
      flexGrow: 1,
    }
}));

function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

export default (props) => {
    const classes = useStyles();
    const theme = useTheme();
    
    return (
        <AppBar position="static" color="lightGrey">
            <Toolbar variant="">
                        
                <Tabs
                    value={props.value}
                    onChange={props.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    >
                    <Tab label={props.examDetail[0].instancename} {...a11yProps(0)} />
                    
                </Tabs>
            </Toolbar>
        </AppBar>
    );
}